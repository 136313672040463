import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function PuneToShirdiTempoTravellerOnRent() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote:"I booked a 17-seater Tempo Traveller from Shiv Travels66 for a family trip from Pune to Shirdi, and the experience was amazing! The vehicle was clean, spacious, and very comfortable for the long journey. Our driver was professional and made sure we had a smooth ride throughout. We also made a stop at Shani Shingnapur, and everything was well-organized. Highly recommend their service for group travel!",
    name: "Mr. Amit Kulkarni"
  },
  {
    quote: "Our group of friends recently rented a 13-seater AC Tempo Traveller from Shiv Travels66 for a Pune to Shirdi trip, and it was a fantastic experience. The booking process was easy, and the vehicle was well-maintained. The driver was punctual, friendly, and very knowledgeable about the route. The journey was comfortable, and we reached Shirdi on time for our darshan. I will definitely choose Shiv Travels66 for future trips!",
    name: "Mrs. Priya Desai"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            "question": "What is the seating capacity of the Tempo Traveller for Pune to Shirdi trips?",
            "answer": "We offer Tempo Travellers with seating capacities ranging from 9 to 17 passengers, depending on your group size."
          },
          {
            "question": "Are both AC and Non-AC Tempo Travellers available for Pune to Shirdi?",
            "answer": "Yes, we provide both AC and Non-AC Tempo Travellers for Pune to Shirdi trips, allowing you to choose according to your comfort and budget."
          },
          {
            "question": "What is the Pune to Shirdi Tempo Traveller fare?",
            "answer": "The fare depends on the seating capacity, type (AC/Non-AC), and duration of the rental. Please contact us for an exact quote."
          },
          {
            "question": "How long does it take to travel from Pune to Shirdi by Tempo Traveller?",
            "answer": "The journey from Pune to Shirdi typically takes around 4-5 hours, depending on traffic and road conditions."
          },
          {
            "question": "Can we book a one-way trip from Pune to Shirdi in a Tempo Traveller?",
            "answer": "Yes, we offer one-way and round-trip services from Pune to Shirdi, giving you flexibility in your travel plans."
          },
          {
            "question": "Is the Tempo Traveller sanitized and well-maintained?",
            "answer": "Yes, all our vehicles are thoroughly sanitized before every trip, and we maintain them to the highest standards for safety and comfort."
          },
          {
            "question": "Can we customize the itinerary for our Pune to Shirdi trip?",
            "answer": "Absolutely! We offer customizable travel plans so you can include stops at Shani Shingnapur, Bhimashankar, or other locations of interest along the route."
          },
          {
            "question": "Do you offer 24/7 customer support for Tempo Traveller bookings?",
            "answer": "Yes, our customer service team is available 24/7 to assist you with bookings, inquiries, and any support you need during your trip."
          },
          {
            "question": "Do you provide drivers with the Tempo Traveller rental?",
            "answer": "Yes, all our Tempo Traveller rentals include experienced and professional drivers familiar with the Pune to Shirdi route."
          },
          {
            "question": "What documents are required to book a Pune to Shirdi Tempo Traveller?",
            "answer": "You’ll need to provide a valid ID proof (such as an Aadhaar card or driving license) and the details of your travel dates and passenger count for booking."
          }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com/Pune-to-Shirdi-Tempo-Traveller-on-Rent",
      "logo": "https://www.shivtravels66.com/img/Pune-to-Shirdi Tempo-Traveller on-Rent.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  

   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];

  

   
  return (
    <>
       <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/img/Pune-to-Shirdi Tempo-Traveller on-Rent.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/Pune-to-Shirdi-Tempo-Traveller-on-Rent" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
     
      {/* <!-- page-title --> */}
      <section class="page-title centred  fleet-section1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Pune to Shirdi Tempo Traveller on Rent </h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Pune-to-Shirdi Tempo-Traveller on-Rent.jpg" alt="Pune-to-Shirdi Tempo-Traveller on-Rent" />
              </div>

              <div>
              <h1>Pune to Shirdi Tempo Traveller on Rent by Shiv Travels66</h1>
<p>Planning a spiritual journey from Pune to Shirdi with family, friends, or a large group? Shiv Travels66 offers convenient and comfortable Tempo Traveller on rent from Pune to Shirdi, ensuring a hassle-free and memorable experience. Whether you’re traveling for pilgrimage or leisure, our well-maintained fleet and professional drivers guarantee a smooth ride.</p>

<h2>Pune to Shirdi Tempo Traveller Hire on Rent</h2>
<p>Pune to Shirdi Tempo Traveller hire on rent is the ideal solution for a comfortable and convenient journey. Shiv Travels66 offers well-maintained Tempo Travellers in various seating capacities (9, 13, 14, 17 seaters) to cater to different group sizes. Our spacious and comfortable vehicles ensure that your pilgrimage to Shirdi is smooth and stress-free. Enjoy an air-conditioned ride with experienced drivers who know the Pune to Shirdi route well, making your trip seamless.</p>

<h3>Key Features:</h3>
<ul>
    <li>Comfortable seating for long-distance travel</li>
    <li>Experienced drivers familiar with the Pune-Shirdi route</li>
    <li>Affordable pricing with no hidden costs</li>
    <li>Flexible pick-up and drop-off points</li>
</ul>

<h2>Pune to Shani Shingnapur Tempo Traveller on Rent</h2>
<p>Planning a trip to Shani Shingnapur? Shiv Travels66 provides Pune to Shani Shingnapur Tempo Traveller on rent for groups. Our well-maintained fleet of Tempo Travellers, ranging from 9 to 17-seaters, ensures a comfortable ride to the revered temple of Lord Shani. The journey from Pune to Shani Shingnapur takes around 4 hours, and with our reliable service, you can make this spiritual trip with ease and comfort.</p>

<h3>Highlights:</h3>
<ul>
    <li>Comfortable seating for groups of all sizes</li>
    <li>Doorstep pick-up and drop-off service</li>
    <li>Safe and secure ride with professional drivers</li>
</ul>

<h2>Pune to Shirdi Tempo Traveller Fare on Rent Per Day</h2>
<p>Shiv Travels66 offers competitive Pune to Shirdi Tempo Traveller fare on rent per day. Our pricing is designed to be affordable without compromising on comfort and quality. Depending on the seating capacity and trip duration, the fare for a Tempo Traveller starts at economical rates. The fare includes vehicle hire, driver charges, and basic amenities, while tolls and parking fees are billed separately.</p>

<h3>Key Points:</h3>
<ul>
    <li>Affordable daily rental rates</li>
    <li>Transparent pricing with no hidden charges</li>
    <li>Flexible booking for one-way or round trips</li>
</ul>

<h2>Pune to Bhimashankar Tempo Traveller on Rent</h2>
<p>For those planning to visit Bhimashankar, Shiv Travels66 offers Pune to Bhimashankar Tempo Traveller on rent. The scenic journey to Bhimashankar, home to one of the 12 Jyotirlingas, takes around 3-4 hours from Pune. Our Tempo Travellers are perfect for group travel, ensuring that everyone in your party enjoys a comfortable and peaceful ride. With ample legroom, air-conditioning, and skilled drivers, your pilgrimage is bound to be relaxing.</p>

<h3>Features:</h3>
<ul>
    <li>Comfortable travel for pilgrimages</li>
    <li>Spacious vehicles with reclining seats</li>
    <li>On-time service with experienced drivers</li>
</ul>

<h2>17 Seater Tempo Traveller on Rent in Pune</h2>
<p>Shiv Travels66 offers 17-seater Tempo Traveller on rent in Pune, perfect for larger families, corporate outings, or pilgrimage groups. Our vehicles are equipped with modern amenities like air-conditioning, comfortable seating, and entertainment options. Whether it's a day trip or an overnight journey, our Tempo Travellers provide a smooth and comfortable ride for all passengers.</p>

<h3>Advantages:</h3>
<ul>
    <li>Spacious vehicle with 17 seats</li>
    <li>Ample luggage space</li>
    <li>Professional and courteous drivers</li>
</ul>

<h2>Pune to 5 Jyotirlinga Darshan Tempo Traveller on Rent</h2>
<p>Shiv Travels66 offers customized Pune to 5 Jyotirlinga Darshan Tempo Traveller on rent for groups. This includes visits to Bhimashankar, Trimbakeshwar, Grishneshwar, Parli Vaijnath, and Aundha Nagnath. Our Tempo Travellers, ranging from 9 to 17 seaters, are equipped with comfortable seating, AC, and professional drivers who are well-versed with the temple routes.</p>

<h3>Package Includes:</h3>
<ul>
    <li>Comfortable travel across multiple destinations</li>
    <li>Well-planned itinerary with temple visits</li>
    <li>Affordable group travel packages</li>
</ul>

<h2>Pune to Shirdi 9, 13, 14, 17 Seater Tempo Traveller on Rent</h2>
<p>At Shiv Travels66, we provide Pune to Shirdi Tempo Travellers in various seating capacities including 9, 13, 14, and 17-seater Tempo Travellers to meet the diverse needs of our clients. Whether you're a small family or a large group, we have the right vehicle for you. Our Tempo Travellers are air-conditioned, well-maintained, and perfect for the 4–5-hour journey from Pune to Shirdi. Experience a hassle-free ride with comfortable seating, experienced drivers, and flexible booking options.</p>

<h3>Options Available:</h3>
<ul>
    <li>9-seater Tempo Traveller: Ideal for small groups</li>
    <li>13-seater and 14-seater: Perfect for mid-sized groups</li>
    <li>17-seater: Best for larger groups or families</li>
</ul>

<h2>Pune to Shirdi AC/Non-AC Tempo Traveller on Rent</h2>
<p>Shiv Travels66 offers both AC and Non-AC Tempo Travellers on rent from Pune to Shirdi, providing flexibility based on your comfort preferences and budget. Whether you're traveling with family or friends, our well-maintained Tempo Travellers ensure a safe, comfortable, and affordable journey. The 200 km drive from Pune to Shirdi takes around 4-5 hours, and our spacious vehicles are perfect for long-distance travel.</p>

<h3>Key Features:</h3>
<ul>
    <li>Choose between AC or Non-AC options</li>
    <li>Comfortable seating for small and large groups</li>
    <li>Affordable rates with no hidden charges</li>
    <li>Professional and experienced drivers</li>
</ul>

<h1>Pune to Ajanta Ellora Tempo Traveller on Rent</h1>
    <p>
        Explore the UNESCO World Heritage sites of Ajanta and Ellora caves with ease by booking a Tempo Traveller on rent from Pune to Ajanta Ellora. Ideal for group tours, our Tempo Travellers offer a comfortable and hassle-free way to visit these ancient landmarks. The journey from Pune to Ajanta Ellora takes approximately 6-7 hours, and our spacious vehicles provide enough room for everyone to relax during the trip.
    </p>

    <h2>Why Choose Shiv Travels66:</h2>
    <ul>
        <li>Well-maintained AC/Non-AC Tempo Travellers</li>
        <li>Professional drivers familiar with the route</li>
        <li>Customizable tour packages for group visits</li>
    </ul>

    <h1>17 Seater Tempo Traveller on Rent in Pune</h1>
    <p>
        For larger groups or corporate outings, our 17-seater Tempo Traveller on rent in Pune is the perfect choice. Equipped with comfortable seating, air-conditioning, and ample luggage space, our vehicles ensure a smooth and enjoyable ride for up to 17 passengers. Whether it’s for a pilgrimage, a sightseeing tour, or a family gathering, our 17-seater Tempo Travellers are ideal for group travel in and around Pune.
    </p>

    <h2>Key Benefits:</h2>
    <ul>
        <li>Spacious and comfortable for large groups</li>
        <li>Ideal for long-distance trips and local tours</li>
        <li>Affordable pricing with flexible rental options</li>
    </ul>

    <h1>14 Seater Tempo Traveller on Rent in Pune</h1>
    <p>
        Shiv Travels66 offers a 14-seater Tempo Traveller on rent in Pune, designed for group trips, corporate travel, and family outings. With comfortable, reclining seats, air conditioning, and ample luggage space, our Tempo Travellers ensure a relaxing journey, whether it's for a day trip or an extended tour.
    </p>

    <h2>Features:</h2>
    <ul>
        <li>14 seats with ample legroom</li>
        <li>Air-conditioned and well-maintained vehicles</li>
        <li>Professional drivers for a safe journey</li>
    </ul>

    <h1>Pune to Nashik Tempo Traveller on Rent</h1>
    <p>
        Book a Pune to Nashik Tempo Traveller on rent with Shiv Travels66. Nashik, located around 4-5 hours from Pune, is famous for its vineyards and religious sites like Trimbakeshwar. Our Tempo Travellers are the perfect choice for group travel, ensuring a comfortable and safe journey for all passengers.
    </p>

    <h2>Highlights:</h2>
    <ul>
        <li>Comfortable seating options (9, 13, 14, 17 seaters available)</li>
        <li>Air-conditioned vehicles for long-distance travel</li>
        <li>Experienced drivers for a smooth trip</li>
    </ul>

    <h1>Pune to Ashtavinayak Tempo Traveller on Rent</h1>
    <p>
        Looking to visit the famous Ashtavinayak temples in Maharashtra? Shiv Travels66 provides Tempo Traveller on rent from Pune to Ashtavinayak, offering a convenient and comfortable way to visit all eight Ganesh temples spread across the state. With our well-maintained Tempo Travellers, your spiritual journey will be peaceful and hassle-free. We offer vehicles with seating capacities ranging from 9 to 17, ideal for small and large groups.
    </p>

    <h2>Advantages:</h2>
    <ul>
        <li>Customizable travel packages for Ashtavinayak Darshan</li>
        <li>Well-maintained AC/Non-AC Tempo Travellers</li>
        <li>Affordable rates with professional drivers</li>
    </ul>

    <h1>Pune to Trimbakeshwar Tempo Traveller on Rent</h1>
    <p>
        For a comfortable and safe journey to the holy city of Trimbakeshwar, Shiv Travels66 offers Tempo Traveller on rent from Pune to Trimbakeshwar. Trimbakeshwar is one of the 12 Jyotirlingas and is located around 5 hours from Pune. Our Tempo Travellers provide a comfortable ride for groups, with vehicles available in multiple seating capacities to accommodate small and large groups alike.
    </p>

    <h2>Benefits:</h2>
    <ul>
        <li>Spacious and comfortable seating options</li>
        <li>Affordable rental rates with flexible booking</li>
        <li>Professional drivers familiar with the pilgrimage route</li>
    </ul>


              </div>

             
              <div>
      <h1>Pune to Shirdi Tempo Traveller on Rent with Shiv Travels66 Contact Number</h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Pune to Shirdi Tempo Traveller on Rent. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Pune to Shirdi Tempo Traveller Hire on Rent</p>
    <p>- Pune to Shani Shingnapur Tempo Traveller on Rent</p>
    <p>- Pune to Shirdi Tempo Traveller Fare on Rent Per Day</p>
    <p>- Pune to Bhimashankar Tempo Traveller on Rent</p>
    <p>- 17 Seater Tempo Traveller on Rent in Pune</p>
    <p>- Pune to 5 Jyotirlinga Darshan Tempo Traveller on Rent</p>
    <p>- Pune to Shirdi 9, 13, 14, 17 Seater Tempo Traveller on Rent</p>
   

          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- Pune to Shirdi AC/Non-AC Tempo Traveller on Rent</p>
    <p>- Pune to Ajanta Ellora Tempo Traveller on Rent</p>
    <p>- 14 Seater Tempo Traveller on Rent in Pune</p>
    <p>- Pune to Nashik Tempo Traveller on Rent</p>
    <p>- Pune to Ashtavinayak Tempo Traveller on Rent</p>
    <p>- Pune to Trimbakeshwar Tempo Traveller on Rent</p>

          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h1>Why Choose Us for Urbania on Rent in Pune with Shiv Travels66?</h1>
    
    <p>When it comes to renting a Urbania in Pune, Shiv Travels66 stands out as a trusted provider offering exceptional service. Whether you’re planning a group outing, a family trip, or a corporate event, our Urbania vehicles are designed to offer both luxury and practicality. Here's why you should choose us:</p>

    <ol>
        <li><strong>Comfortable and Spacious Vehicles</strong><br/>
            Our Urbania fleet provides ample seating and legroom, ensuring that all passengers enjoy a comfortable ride. With spacious interiors and modern amenities, it’s perfect for long-distance travel and local tours.
        </li>
        <li><strong>Well-Maintained Fleet</strong><br/>
            At Shiv Travels66, we pride ourselves on keeping our vehicles in top condition. Our Urbania vehicles undergo regular maintenance and inspections to ensure safety, reliability, and comfort on every trip.
        </li>
        <li><strong>Experienced and Professional Drivers</strong><br/>
            Our drivers are highly experienced and trained to navigate Pune’s roads and the surrounding areas with ease. They prioritize safety while ensuring timely arrivals and departures for a smooth journey.
        </li>
        <li><strong>Affordable Pricing with No Hidden Costs</strong><br/>
            We offer competitive rates with transparent pricing. Our Urbania rental service comes with flexible packages that suit your budget. There are no hidden fees, so you know exactly what you’re paying for.
        </li>
        <li><strong>Flexible Rental Options</strong><br/>
            Whether you need a Urbania for a few hours, a full day, or an extended period, we offer flexible rental options. You can choose from one-way or round-trip services, making it convenient for all types of travel plans.
        </li>
        <li><strong>Customized Travel Solutions</strong><br/>
            We understand that every trip is unique. That’s why we offer customized solutions to meet your specific requirements. Whether it's a family event, a business trip, or a weekend getaway, we tailor our services to meet your needs.
        </li>
        <li><strong>24/7 Customer Support</strong><br/>
            Our team is available round-the-clock to assist you with bookings, inquiries, and support during your journey. We are committed to providing excellent customer service, ensuring a hassle-free experience.
        </li>
        <li><strong>Safety First</strong><br/>
            Your safety is our top priority. All our vehicles are equipped with modern safety features, and our drivers adhere to all safety regulations to make sure you have a worry-free journey.
        </li>
        <li><strong>Punctuality and Reliability</strong><br/>
            When you choose Shiv Travels66, you can trust us to be on time. Whether it’s for an airport transfer, a local event, or a long-distance journey, we ensure punctuality and reliability in our services.
        </li>
    </ol>

    </div>
      
    <div className="faq-container">
    <h2>FAQs for Shiv Travels66 " Pune to Shirdi Tempo Traveller on Rent “?</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 “Pune to Shirdi Tempo Traveller on Rent ":- </h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>

          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
