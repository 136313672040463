import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";
export default function InnovaCrystaOnRentInPune() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote:"I recently rented an Innova Crysta from Shiv Travels66 for a family trip to Lonavala, and I couldn't be happier with the service! The vehicle was immaculate, spacious, and incredibly comfortable for our group of six. Our driver was punctual, friendly, and knowledgeable about the area, making our journey even more enjoyable. I highly recommend Shiv Travels66 for anyone looking for reliable car rentals in Pune!",
    name: "Mrs.Anita Deshmukh"
  },
  {
    quote:"Shiv Travels66 provided an excellent experience when I hired an Innova Crysta for a corporate event. The booking process was smooth, and the vehicle was in perfect condition, equipped with all the amenities we needed. Our driver was professional and accommodating, ensuring that we arrived at our destination on time. I will definitely choose Shiv Travels66 again for future rentals!",
    name: "Mr. Rohit Patil"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            "question": "What is the seating capacity of the Innova Crysta?",
            "answer": "The Innova Crysta can comfortably seat up to 7 passengers, making it ideal for family trips, group outings, or corporate travel."
        },
        {
            "question": "Are the Innova Crysta vehicles air-conditioned?",
            "answer": "Yes, all our Innova Crysta vehicles are fully air-conditioned to ensure a comfortable journey, regardless of the weather."
        },
        {
            "question": "Can I hire an Innova Crysta for outstation trips?",
            "answer": "Absolutely! Our Innova Crysta is available for both local and outstation trips. Whether you're traveling to nearby destinations or farther locations, we've got you covered."
        },
        {
            "question": "How do I book an Innova Crysta with Shiv Travels66?",
            "answer": "Booking is easy! You can contact us via phone, email, or through our website. Simply provide your travel details, and our team will assist you with the booking process."
        },
        {
            "question": "Is a driver included in the rental service?",
            "answer": "Yes, all our Innova Crysta rentals come with a professional driver who is knowledgeable about the area and committed to ensuring your safety and comfort during the journey."
        },
        {
            "question": "What are the rental charges for the Innova Crysta?",
            "answer": "Our rental charges depend on factors such as duration, distance, and the type of trip. We provide transparent pricing with no hidden fees. Please contact us for a personalized quote based on your requirements."
        },
        {
            "question": "Can I request additional services, such as child seats or special amenities?",
            "answer": "Yes, we offer additional services upon request, including child seats and other amenities to make your journey more comfortable. Please inform us during the booking process so we can accommodate your needs."
        },
        {
            "question": "What is the cancellation policy for Innova Crysta rentals?",
            "answer": "Our cancellation policy is flexible. Please reach out to our customer service team for specific details regarding cancellations and any applicable fees."
        },
        {
            "question": "Are there any mileage restrictions on the Innova Crysta rentals?",
            "answer": "We typically have mileage policies based on the type of trip. For local rentals, there may be unlimited mileage, while outstation trips might have a set limit. Please inquire during your booking for more details."
        },
        {
            "question": "What payment methods do you accept for Innova Crysta rentals?",
            "answer": "We accept various payment methods, including cash, credit/debit cards, and online payment options. You can choose the method that is most convenient for you during the booking process."
        },
        {
            "question": "What are the available seating options for Urbania on rent in Pune?",
            "answer": "Shiv Travels66 offers Urbania vehicles with 10-seater, 13-seater, and 17-seater options, making them ideal for small to medium-sized groups."
        },
        {
            "question": "How can I book an Urbania on rent in Pune?",
            "answer": "You can easily book an Urbania by calling us at [Your Contact Number], visiting our website, or sending us an inquiry through our contact form. We’ll confirm your booking and provide all the necessary details."
        }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]


    const vehicles = [
      {
        type: ' Force Urbania ',
        capacity: '13-Seater/ 17-Seater',
        rates: 'Rs.10,800',
        perDay: 'Rs.36/ Per Km',
        driverDA: 'Rs.400',
        booking: 'Available',
        path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
      },
      {
        type: 'Tempo Traveller AC',
        capacity: '13-Seater, 17-Seater',
        rates: 'Rs.7,800',
        perDay: 'Rs.26/ Per Km',
        driverDA: 'Rs.400',
        booking: 'Available',
        path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
      }, 
      {
        type: 'Tempo Traveller Non-AC',
        capacity: '13-Seater, 17-Seater',
        rates: 'Rs.6,900',
        perDay: 'Rs.23/ Per Km',
        driverDA: 'Rs.400',
        booking: 'Available',
        path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
      }, 
      {
        type: 'Innova Crysta',
        capacity: '6+1 Seater',
        rates: 'Rs.5,700',
        perDay: '19/ Per Km',
        driverDA: 'Rs.400',
        booking: 'Available',
        path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
      },
    ];
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com",
      "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1.3",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  
  

   
  return (
    <>
      <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/img/Innova-Crysta-on Rent-in-Pune.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/Innova-Crysta-on-Rent-in-Pune" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune, Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
     
      {/* <!-- page-title --> */}
      <section class="page-title centred car-img2">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels</span>
            <h1>Innova Crysta on Rent in Pune</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Innova-Crysta-on Rent-in-Pune.jpg" alt="Innova Cryata on Rent in Pune" />
              </div>

              <div>
              <h1>Innova Crysta on Rent in Pune with Shiv Travels66</h1>
<p>When it comes to luxury travel in Pune, nothing beats the comfort and elegance of the Innova Crysta. At Shiv Travels66, we offer top-of-the-line Innova Crysta on rent to meet all your travel needs, whether for family trips, corporate outings, or special occasions.</p>

<h2>Innova on Rent Pune</h2>
<p>Experience the perfect blend of comfort and style with our Innova on rent in Pune. With spacious interiors and premium features, it’s an ideal choice for both short trips and long journeys.</p>

<h2>Innova Crysta on Rent Pune</h2>
<p>The Innova Crysta is a standout choice for travelers seeking luxury and space. This vehicle is equipped with the latest amenities and ensures a smooth ride, making it perfect for any occasion.</p>

<h2>Innova Car Hire in Pune</h2>
<p>Whether you need an Innova for a wedding, corporate event, or family vacation, our Innova car hire in Pune offers flexibility and convenience. Choose from hourly, daily, or long-term rental options.</p>

<h2>Innova Crysta Rental Pune</h2>
<p>Opt for our Innova Crysta rental in Pune for a premium travel experience. With top-notch safety features and spacious seating, you can travel with confidence and comfort.</p>

<h2>Innova Car Rental in Pune</h2>
<p>At Shiv Travels66, we provide Innova car rental in Pune tailored to your specific needs. Whether you’re planning a city tour or an outstation trip, our vehicles are ready for any journey.</p>

<h2>Innova Car Rental Per Km in Pune</h2>
<p>Our pricing for Innova car rental per km in Pune is competitive and transparent. You’ll know exactly what you’re paying for, with no hidden costs involved.</p>

<h2>Innova Crysta Rent in Pune</h2>
<p>Choose Innova Crysta rent in Pune for a luxurious travel experience. Our vehicles are well-maintained and equipped with all the features you need for a comfortable ride.</p>

<h2>Pune Innova Rental</h2>
<p>For those seeking convenience and comfort, Pune Innova rental services by Shiv Travels66 are your best bet. We cater to all types of travel, ensuring a memorable experience.</p>

<h2>Pune Mumbai Innova Rental</h2>
<p>Our Pune Mumbai Innova rental service provides the perfect solution for a comfortable and hassle-free journey. With spacious seating and luxurious interiors, the Innova ensures you arrive at your destination relaxed and on time.</p>

<h2>Innova Crysta on Rent in Pimpri Chinchwad</h2>
<p>If you’re located in Pimpri Chinchwad, our Innova Crysta on rent in Pimpri Chinchwad service is at your disposal. Enjoy the convenience of door-to-door service with a professional driver who knows the area well.</p>

<h2>Innova Crysta on Rent in Hadapsar</h2>
<p>Our Innova Crysta on rent in Hadapsar is perfect for local travel or long-distance trips. Experience the blend of luxury and comfort with every ride.</p>

<h2>Innova Crysta on Rent in Kharadi</h2>
<p>Kharadi is a bustling area, and traveling around can be challenging. Opt for our Innova Crysta on rent in Kharadi to navigate the busy streets with ease. Our well-maintained vehicles will ensure a smooth ride to your destination.</p>

<h2>Innova Crysta on Rent in Viman Nagar</h2>
<p>Viman Nagar is known for its vibrant atmosphere, and our Innova Crysta on rent in Viman Nagar offers you the freedom to explore the area comfortably. Perfect for family outings, airport transfers, or special occasions, our service guarantees satisfaction.</p>

<h2>Innova Crysta on Rent in Koregaon Park</h2>
<p>For those in Koregaon Park, we provide the Innova Crysta on rent in Koregaon Park service, ideal for leisure and business travel. Enjoy the luxury of spacious seating and a stylish interior while being driven by our experienced chauffeurs.</p>

              </div>
             

              <div>
      <h1>Innova Crysta on Rent in Pune with Shiv Travels66 Contact Number:- </h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Innova on rent Pune</p>
<p>- Innova Crysta on rent Pune</p>
<p>- Innova car hire in Pune</p>
<p>- Innova Crysta rental Pune</p>
<p>- Innova car rental in Pune</p>
<p>- Innova car rental per km in Pune</p>
<p>- Innova Crysta rent in Pune</p>



          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- Pune Innova rental</p>
<p>- Pune Mumbai Innova rental</p>
<p>- Innova Crysta on rent in Pimpri Chinchwad</p>
<p>- Innova Crysta on rent in Hadapsar</p>
<p>- Innova Crysta on rent in Kharadi</p>
<p>- Innova Crysta on rent in Viman Nagar</p>
<p>- Innova Crysta on rent in Koregaon Park</p>
          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h1>Why Choose Us for Innova Crysta on Rent in Pune with Shiv Travels66?</h1>
<p>When it comes to renting an Innova Crysta in Pune, Shiv Travels66 stands out for several reasons. Here’s why you should choose us for your travel needs:</p>
<ol>
    <li>
        <strong>Luxury and Comfort:</strong> Our Innova Crysta vehicles are designed to provide a luxurious travel experience. With spacious interiors, comfortable seating, and modern amenities, you can enjoy every journey, whether it’s a family trip or a corporate outing.
    </li>
    <li>
        <strong>Well-Maintained Fleet:</strong> We take pride in our fleet of well-maintained vehicles. Each Innova Crysta undergoes regular servicing and thorough inspections to ensure safety and reliability. You can trust that our cars are in top condition for your travels.
    </li>
    <li>
        <strong>Experienced Drivers:</strong> Our professional drivers are knowledgeable and experienced, ensuring you navigate Pune and its surroundings smoothly. They are trained to provide excellent customer service, ensuring your comfort and safety throughout the journey.
    </li>
    <li>
        <strong>Flexible Booking Options:</strong> We understand that every traveler has unique needs. That’s why we offer flexible booking options, whether you need an Innova Crysta for a few hours, a full day, or longer. Our services can be tailored to fit your schedule.
    </li>
    <li>
        <strong>Transparent Pricing:</strong> With Shiv Travels66, you can expect competitive and transparent pricing. We provide clear quotes with no hidden fees, so you know exactly what you’re paying for. Our aim is to offer the best value for your money.
    </li>
    <li>
        <strong>24/7 Customer Support:</strong> Our customer service team is available around the clock to assist you with bookings, inquiries, and any special requests. We prioritize your satisfaction and are here to help you at any time.
    </li>
    <li>
        <strong>Wide Range of Destinations:</strong> Whether you’re traveling locally within Pune or planning a trip to nearby destinations, our Innova Crysta on rent is the perfect choice. We cater to various travel needs, from airport transfers to family outings and corporate events.
    </li>
    <li>
        <strong>Customer-Centric Approach:</strong> At Shiv Travels66, customer satisfaction is our top priority. We go the extra mile to ensure a smooth and enjoyable experience, from the moment you book until you reach your destination.
    </li>
    <li>
        <strong>Positive Reviews and Testimonials:</strong> We take pride in our satisfied customers. Many travelers have shared their positive experiences with our Innova Crysta rentals, highlighting our professionalism, reliability, and exceptional service.
    </li>
    <li>
        <strong>Convenient Booking Process:</strong> Booking your Innova Crysta on rent with us is quick and straightforward. You can easily make reservations via our website, phone, or in person, ensuring a hassle-free experience.
    </li>
</ol>

    </div>
      
    <div className="faq-container">
    <h2>FAQs for Innova Crysta on Rent in Pune with Shiv Travels66?</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 regarding Innova Crysta on Rent in Pune: -</h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>


          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
