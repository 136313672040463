import "./App.css";
import { Helmet } from "react-helmet";


export default function Packages(){
  const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];

      const schemaData = {
        "@context": "https://schema.org",
        "@type": "TravelAgency",
        "name": "Shiv Travels66",
        "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
        "url": "https://www.shivtravels66.com",
        "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
            "addressLocality": "Kirtane Baug Hadpasar",
            "addressRegion": "Maharashtra",
            "postalCode": "411036",
            "addressCountry": "India"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91 9764768866",
            "contactType": "Customer Service",
            "availableLanguage": "English"
        },
        "email": "booking@shivtravels66.com",
        telephone:"+91 9764768866",
        image: "https://www.shivtravels66.com/OG Image-01.jpg",
        starRating: {
          "@type": "Rating",
          bestRating: "4.8",
          ratingValue: "3",
          worstRating: "1",
        },
        "keywords": [
          "Urbania On Rent in Pune",
          "Pune to Mahabaleshwar Urbania on Rent",
          "Pune to Shirdi Urbania on Rent",
          "Urbania Hire for Outstation Pune",
          "Urbania Hire for Corporate Events in Pune"
      ]
    
    };
    

    const guidelines = [
      {
        title: "Easy Booking",
        content: [
          "Advance Booking: We encourage you to book your travel at least 24 hours in advance for a smooth experience.",
          "Confirmation: You will receive a booking confirmation via email or SMS."
        ],
        marathi: [
          "आगाऊ बुकिंग: आपला प्रवास किमान 24 तास आधी बुक करणे आवश्यक आहे, ज्यामुळे आपला अनुभव अधिक सोयीस्कर होईल.",
          "पुष्टीकरण: आपल्याला ई-मेल किंवा एसएमएस द्वारे बुकिंगची पुष्टी मिळेल."
        ]
      },
      {
        title: "Flexible Payment Options",
        content: [
          "Multiple Payment Methods: Enjoy the convenience of paying through credit/debit cards, UPI, or cash for local bookings.",
          "Transparent Pricing: All costs will be clearly communicated during the booking process."
        ],
        marathi: [
          "अनेक भरणा पद्धती: क्रेडिट/डेबिट कार्ड, युपीआय, किंवा स्थानिक बुकिंगसाठी रोख पैसे भरण्याची सोय उपलब्ध आहे.",
          "स्पष्ट किमती: बुकिंग प्रक्रियेदरम्यान सर्व खर्च स्पष्टपणे सांगितले जातील."
        ]
      },
      {
        title: "Refund Policy",
        content: [
          "Full Refund: Cancel your trip 48 hours before departure for a full refund, no questions asked.",
          "Supportive Team: Our customer service team is here to assist you with any cancellation requests."
        ],
        marathi: [
          "पूर्ण परतावा: प्रवासाच्या 48 तास आधी रद्द केल्यास पूर्ण परतावा मिळतो, कोणतेही प्रश्न नाहीत.",
          "सहाय्यक टीम: आमची ग्राहक सेवा टीम आपल्याला रद्दीकरणाच्या विनंत्या पूर्ण करण्यास मदत करण्यासाठी येथे आहे."
        ]
      },
      {
        title: "Luggage Support",
        content: [
          "Luggage Limit: Each passenger can carry two pieces of luggage (up to 15 kg each) and one carry-on item.",
          "Care for Your Belongings: We prioritize the safety of your luggage throughout your journey."
        ],
        marathi: [
          "सामानाची मर्यादा: प्रत्येक प्रवाश्यासाठी दोन सामानाचे तुकडे (प्रत्येक 15 किलोग्रामपर्यंत) आणि एक कॅरी-ऑन वस्तू आणण्याची परवानगी आहे.",
          "आपल्या वस्तूंची काळजी: आपल्या प्रवासाच्या दरम्यान आपल्या सामानाची सुरक्षा आमच्या प्राथमिकतेत आहे."
        ]
      },
      {
        title: "Positive Conduct",
        content: [
          "Respectful Behavior: We promote a friendly environment. Please treat our staff and fellow passengers with respect.",
          "Enjoy Your Journey: Make the most of your travel experience by maintaining a positive attitude!"
        ],
        marathi: [
          "आदरपूर्वक वर्तन: आम्ही मित्रत्वाचा वातावरण तयार करण्यास प्रोत्साहन देतो. कृपया आमच्या कर्मचाऱ्यांशी आणि इतर प्रवाशांशी आदराने वागा.",
          "आपला प्रवास आनंदित करा: सकारात्मक दृष्टिकोन ठेवून आपल्या प्रवासाचा अनुभव अधिक चांगला करा!"
        ]
      },
      {
        title: "Vehicle Usage",
        content: [
          "Purposeful Use: Our vehicles are for your travel needs—enjoy sightseeing or comfortable transportation.",
          "Safe Environment: We maintain a smoke-free and alcohol-free atmosphere inside our vehicles."
        ],
        marathi: [
          "उद्देशपूर्ण वापर: आमची वाहने आपल्या प्रवासाच्या गरजांसाठी आहेत—पर्यटन किंवा आरामदायी वाहतूक अनुभवण्याचा आनंद घ्या.",
          "सुरक्षित वातावरण: आमच्या वाहनांमध्ये धूम्रपान आणि मद्यपान यांना मनाई आहे."
        ]
      },
      {
        title: "Supportive Drivers",
        content: [
          "Experienced Drivers: Our professional drivers are here to ensure your safety and comfort throughout the journey.",
          "Follow Instructions: Please follow the driver's guidance for a safe and enjoyable trip."
        ],
        marathi: [
          "अनुभवी ड्रायव्हर्स: आमचे व्यावसायिक ड्रायव्हर्स आपल्या सुरक्षिततेसाठी आणि आरामासाठी येथे आहेत.",
          "सूचना पालन: सुरक्षित आणि आनंददायक प्रवासासाठी कृपया ड्रायव्हरच्या मार्गदर्शक सूचनांचे पालन करा."
        ]
      },
      {
        title: "Safety First",
        content: [
          "Insurance: All vehicles are insured for your safety. We recommend obtaining travel insurance for added peace of mind.",
          "Emergency Protocols: Our drivers are trained to handle emergencies calmly and efficiently."
        ],
        marathi: [
          "बीमा: सर्व वाहने आपल्यासाठी बीमांकित आहेत. आपल्याला मनःशांतीसाठी प्रवास बीमा मिळवण्याची शिफारस केली जाते.",
          "आपत्कालीन प्रोटोकॉल: आमचे ड्रायव्हर्स आपत्कालीन परिस्थिती हाताळण्यात प्रशिक्षित आहेत."
        ]
      },
      {
        title: "We Value Your Feedback",
        content: [
          "Feedback Encouraged: Your thoughts are important to us! Please share your experiences to help us improve.",
          "Customer Service: Our team is always available to address any questions or concerns."
        ],
        marathi: [
          "अभिप्राय प्रोत्साहित केला जातो: आपले विचार आमच्यासाठी महत्वाचे आहेत! कृपया आपल्या अनुभवांचे शेअर करा ज्यामुळे आम्ही सुधारणा करू शकू.",
          "ग्राहक सेवा: आमची टीम कोणत्याही प्रश्न किंवा चिंतेसाठी नेहमी उपलब्ध आहे."
        ]
      }
    ];
    
    return(
        <>
          <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
        <section class="page-title centred background1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(background/2.jpg);",
          }}
        ></div>

        {/* ==================== */}
        <div class="container">
          <div class="content-box">
            <span>our</span>
            <h1>Packages</h1>
          </div>
        </div>
      </section>

     <section className="pricing-section  text-center table-section">

       <h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
       </h5>
       <div className="table-responsive mt-4 ">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Type Of Vehicle</th>
            <th>Outstation Capacity</th>
            <th>Outstation Rates</th>
            <th>Outstation Per Day (300km)</th>
            <th>Per Day Driver D.A.</th>
            <th>Outstation Booking</th>
          </tr>
        </thead>
        <tbody>
          {vehicles.map((vehicle, index) => (
            <tr key={index}>
              <td data-label="Type Of Vehicle">{vehicle.type}</td>
              <td data-label="Outstation Capacity">{vehicle.capacity}</td>
              <td data-label="Outstation Rates">{vehicle.rates}</td>
              <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
              <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
              <td>
              <div className="">
  

  <a href={vehicle.path} target="_blank"
    rel="noopener noreferrer"
    className="btn fleet-btn">Book Now</a>
</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </section> 

    <div className="container mt-5 pb-3">
      <h2 className="text-center">Rules and Guidelines</h2>
      {guidelines.map((item, index) => (
        <div key={index} className="mt-4">
          <h4>{item.title}</h4>
         
          <ul>
            {item.content.map((line, idx) => (
              <li key={idx}>{line}</li>
            ))}
          </ul>
          
          <ul>
            {item.marathi.map((line, idx) => (
              <li key={idx}>{line}</li>
            ))}
          </ul>
        </div>
      ))}
    </div> 
    
        </>
    )
}