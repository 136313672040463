import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function InnovaCrystaCab() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "I recently hired an Innova Crysta cab from Shiv Travels66 for a family trip to Mahabaleshwar, and I couldn't be happier with the service! The cab was clean, comfortable, and well-maintained. Our driver was not only punctual but also very friendly and knowledgeable about the area. He made our journey enjoyable and stress-free. I highly recommend Shiv Travels66 for anyone looking for reliable cab services in Pune!",
    name: "Mrs. Anjali Deshmukh"
  },
  {
    quote: "Our experience with Shiv Travels66 was fantastic! We booked the Innova Crysta for a wedding event, and it was perfect for transporting our guests. The cab was spacious and luxurious, making everyone feel comfortable during the ride. The driver was courteous and ensured everyone reached the venue on time. I will definitely choose Shiv Travels66 again for future trips!",
    name: "Mr.Rajesh Kumar"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            question: "What is the capacity of an Innova Crysta cab?",
            answer: "The Innova Crysta cab can comfortably seat up to 7 passengers, making it ideal for family trips, group outings, or corporate travel."
        },
        {
            question: "Is the Innova Crysta cab available for outstation travel?",
            answer: "Yes, we offer Innova Crysta cabs for both local and outstation travel. You can book the cab for destinations across Maharashtra and beyond."
        },
        {
            question: "What amenities are included in the Innova Crysta cab?",
            answer: "Our Innova Crysta cabs come equipped with air conditioning, comfortable seating, a music system, and ample luggage space. Some vehicles may also have additional features like charging points and Wi-Fi."
        },
        {
            question: "How do I book an Innova Crysta cab with Shiv Travels66?",
            answer: "You can book an Innova Crysta cab through our website, mobile app, or by contacting our customer support team. Just provide your travel details, and we'll assist you with the booking process."
        },
        {
            question: "What are the payment options available for booking?",
            answer: "We accept various payment methods, including online payments, bank transfers, and cash payments. You can choose the option that is most convenient for you."
        },
        {
            question: "What are the cancellation policies for the Innova Crysta cab?",
            answer: "We offer flexible cancellation policies. Please refer to our terms and conditions for specific details regarding cancellation fees and timelines."
        },
        {
            question: "Are there any additional charges for outstation travel?",
            answer: "Yes, outstation travel may incur additional charges based on distance, duration, and any specific requests. Our team will provide a clear breakdown of costs when you book."
        },
        {
            question: "Are the drivers experienced and well-trained?",
            answer: "Absolutely! Our drivers are professional, experienced, and well-trained to ensure your safety and comfort during the journey."
        },
        {
            question: "Can I request a specific pick-up and drop-off location?",
            answer: "Yes, you can specify your preferred pick-up and drop-off locations while booking your Innova Crysta cab. We strive to accommodate your needs as much as possible."
        },
        {
            question: "Is the Innova Crysta cab sanitized before every trip?",
            answer: "Yes, we prioritize your safety. All our vehicles, including the Innova Crysta cabs, are thoroughly sanitized before each trip to ensure a clean and safe environment for our passengers."
        }
      ];

      const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'hotel-in-viman-nagar'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'viman-nagar-hotels-for-stay'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'lodge-in-viman-nagar-pune'
        },
    ]
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com",
      "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  
  

   
  return (
    <>
     <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Terms|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
     
      {/* <!-- page-title --> */}
      <section class="page-title centred policy-bg">
        <div
          class="bg-layer"
        
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Top Places</span>
            <h1>Terms and Conditions</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Termes and Condition.jpg" alt="Policy Image" />
              </div>
           
              
              <div class="container">
<h1>Terms and Conditions for Shiv Travels66</h1>

<h2>Welcome to Shiv Travels66!</h2>
<p>By accessing our website and using our services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</p>

<h2>1. Acceptance of Terms</h2>
<p>By using our website and services, you acknowledge that you have read, understood, and agree to these Terms and Conditions. If you do not agree, please do not use our services.</p>

<h2>2. Services Offered</h2>
<p>Shiv Travels66 provides travel-related services, including but not limited to booking transportation, accommodations, and tours. We reserve the right to modify or discontinue our services at any time without prior notice.</p>

<h2>3. User Responsibilities</h2>
<p>You agree to:</p>
<ul>
    <li>Provide accurate and complete information when booking services.</li>
    <li>Ensure that all payment information is valid and that you have sufficient funds to cover any purchases.</li>
    <li>Use our services only for lawful purposes and in accordance with these Terms and Conditions.</li>
</ul>

<h2>4. Booking and Payment</h2>
<ul>
    <li>All bookings are subject to availability.</li>
    <li>Payments must be made at the time of booking unless otherwise specified.</li>
    <li>Prices are subject to change, and you will be notified of any changes before finalizing your booking.</li>
</ul>

<h2>5. Cancellations and Refunds</h2>
<p>Cancellation policies vary by service provider. Please review the specific cancellation policy for each booking.</p>
<p>Refunds, if applicable, will be processed according to the provider's terms and may take several business days.</p>

<h2>6. Liability</h2>
<p>Shiv Travels66 is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services. We are not responsible for any third-party services or accommodations provided through our platform.</p>

<h2>7. Intellectual Property</h2>
<p>All content on the Shiv Travels66 website, including text, graphics, logos, and images, is the property of Shiv Travels66 and is protected by copyright laws. Unauthorized use of any content is prohibited.</p>

<h2>8. Privacy Policy</h2>
<p>Your use of our services is also governed by our Privacy Policy, which can be found <a href="#">here</a>. Please review our Privacy Policy to understand how we collect and use your information.</p>

<h2>9. Changes to Terms and Conditions</h2>
<p>We may update these Terms and Conditions from time to time. We will notify you of any changes by posting the new terms on our website. Your continued use of our services after any changes constitute your acceptance of the new terms.</p>

                </div>


                 {/* ================================ */}
                 <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              
              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
