import "./About.css";
import Slider from 'react-slick';
import { Helmet } from "react-helmet";

export default function About(){
    const newsItems1 = [
      
        {
            category: "Covid Travel",
            date: "19 Mar, 2023",
            title: "Corporate Travel Solutions",
            image: 'services/coprate.png',
            link: "Urbania-Hire-for-Corporate-Events-in-Pune",
            para:'Corporate travel solutions provided by cab services typically include a range of features and services tailored to meet the needs of businesses and their employees.'
        },
        {
            category: "Europe",
            date: "18 Mar, 2023",
            title: "24/7 Customer Service",
            image: 'services/Customer Support.png',
            link: "Tempo-Traveller-Hire-for-Wedding-in-Pune",
            para:'Assistance with booking cabs at any hour, whether online, through an app, or via phone.Support for changes to your itinerary, such as rescheduling pickup times etc.'
        },
        {
            category: "Europe",
            date: "18 Mar, 2023",
            title: "Experienced Drivers",
            image: 'services/experinDriver (1).png',
            link: "Urbania-on-rent-in-puneml",
            para:'Our professional drivers undergo rigorous training to ensure your safety and comfort, with in-depth knowledge of routes and destinations, navigating any road with ease.'
        },
    ];

    const testimonialSetting = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    
      // ================ Testimonial Section ===========
      const testimonialsData = [
        {
          name: "Mr.Rahul Patil.",
          img: "testimonial/testG1.png",
          feedback:
            "Shiv Travels66 made our trip unforgettable! The service was exceptional, and the driver was friendly and professional. Highly recommend!",
        },
        {
          name: "Mrs.Rakhi Sawant",
          img: "testimonial/testL1.png",
          feedback:
            "I booked a family vacation with Shiv Travels66, and everything was perfectly organized. From pick-up to drop-off, it was smooth sailing!",
        },
        {
          name: "Mr.Raj Patel.",
          img: "testimonial/testG2.png",
          feedback:
            "What a wonderful experience! The team at Shiv Travels66 was attentive to our needs and ensured we had a great time exploring the sights.",
        },
        {
          name: "Mrs.Sakshi Desai.",
          img: "testimonial/testL3.png",
          feedback:
            "I can't thank Shiv Travels66 enough for their excellent service. The vehicle was clean and comfortable, and our driver was very knowledgeable about the area.",
        },
        {
          name: "Mr.Gaurav Naik.",
          img: "testimonial/testG3.png",
          feedback:
            "Shiv Travels66 offers top-notch service at great prices. I felt valued as a customer, and they went above and beyond to make our trip special.",
        },
        {
          name: "Mrs.Reema Shekh.",
          img: "testimonial/testL4.png",
          feedback:
            "From start to finish, Shiv Travels66 provided an amazing experience. The booking process was easy, and the team was always ready to assist.",
        },
        {
          name: "Mr.Raj Kolahtkar.",
          img: "testimonial/testG5.png",
          feedback:
            "Shiv Travels66 made our business trip seamless. They were punctual, reliable, and made sure we arrived on time for all our meetings.",
        },
        
      ];

      const schemaData = {
        "@context": "https://schema.org",
        "@type": "TravelAgency",
        "name": "Shiv Travels66",
        "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
        "url": "https://www.shivtravels66.com",
        "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
            "addressLocality": "Kirtane Baug Hadpasar",
            "addressRegion": "Maharashtra",
            "postalCode": "411036",
            "addressCountry": "India"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91 9764768866",
            "contactType": "Customer Service",
            "availableLanguage": "English"
        },
        "email": "booking@shivtravels66.com",
        telephone:"+91 9764768866",
        image: "https://www.shivtravels66.com/OG Image-01.jpg",
        starRating: {
          "@type": "Rating",
          bestRating: "4.8",
          ratingValue: "3",
          worstRating: "1",
        },
        "keywords": [
          "Urbania On Rent in Pune",
          "Pune to Mahabaleshwar Urbania on Rent",
          "Pune to Shirdi Urbania on Rent",
          "Urbania Hire for Outstation Pune",
          "Urbania Hire for Corporate Events in Pune"
      ]
    
    };
    const styles = {
        container: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'auto',
          backgroundImage: 'url("background/2.jpg")', // Replace with your background image URL
          backgroundSize: 'cover',
          color: '#fff',
          textAlign: 'center',
          padding: '20px',
        },
        heading: {
          fontSize: '2.5em',
          margin: '10px 0',
          fontFamily: '"Times New Roman", Times, serif',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
          color:'#fff',
        },
        mission: {
          fontSize: '1.5em',
          margin: '20px 0',
          fontFamily: '"Arial", sans-serif',
          lineHeight: '1.6',
          maxWidth: '600px',
          color:'#fff',
        },
        list: {
          listStyleType: 'none',
          padding: 0,
          fontSize: '1.2em',
        },
        listItem: {
          margin: '10px 0',
          textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)',
        },
        imageContainer: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px 0',
        },
        image: {
        //   width: '150px',
        //   height: '150px',
          margin: '0 20px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
        },
      };
    
    return(
        <>
         <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|About|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages starting at ₹299." />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and realx free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing beach vacation with our exclusive packages." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
             {/* <!-- page-title --> */}
      <section class="page-title centred about-section">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(../public/background/Hero images-01.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>About Us</h1>
          </div>
        </div>
      </section>
      {/* =========================== */}


      <div className="about-container">
      <div className="about-grid">
        <div className="about-content">
          <h2>About Shiv Travels66</h2>
          <p>
            At Shiv Travels66, we are dedicated to providing top-notch travel services 
            tailored to your needs. Our mission is to make your journey seamless, enjoyable, 
            and unforgettable. With years of experience in the travel industry, we offer 
            a wide range of services including personalized travel packages, hotel bookings, 
            and transportation solutions.
          </p>
          <p>
            Our team of travel experts is passionate about helping you discover the best 
            destinations and experiences. Whether you are planning a family vacation, a 
            romantic getaway, or a corporate trip, we are here to assist you every step 
            of the way. Choose Shiv Travels66 for reliable, affordable, and memorable travel.
          </p>
        </div>
        <div className="about-image">
          <img src='real/shivtravels-aboutimg.jpg' alt="Shiv travels66"></img>
        </div>
      </div>
    </div>

    <div style={styles.container}>
        <div className="row container">
            <div className="col-12 col-md-6">
            <h1 style={styles.heading}>Vision</h1>
      <div style={styles.imageContainer}>
        <img
          src="img/Vision & Mission Section-02.jpg" // Replace with your vision image URL
          alt="Vision"
          style={styles.image}
        />
      </div>
      <p style={styles.mission}>
        "To be the leading travel partner, inspiring journeys that connect people and cultures, while fostering unforgettable memories and experiences."
      </p>

            </div>
            <div className="col-12 col-md-6">
            <h1 style={styles.heading}>Mission</h1>
      <div style={styles.imageContainer}>
        <img
          src="img/Vision & Mission Section-01.jpg" // Replace with your mission image URL
          alt="Mission"
          style={styles.image}
        />
      </div>
      <p style={styles.mission}>
        "At Shiv Travels66, we are committed to providing exceptional travel experiences through personalized services, diverse travel options, and a dedication to customer satisfaction."
      </p>
   
            </div>
        </div>
     
     
    </div>

    {/* ========================== */}
    <section className="testimonial mytestimonial-section testimonialsection py-100">
        <div className="container">
          <div className="row container">
            <h1 className="text-white text-center pb-3">Testimonial</h1>
            <div className="col-md-10 offset-md-1">
              <Slider {...testimonialSetting}>
                {testimonialsData.map((testimonial, index) => (
                  <div className="item-box" key={index}>
                    <div className="text-box text-center">
                      {testimonial.feedback}
                    </div>
                    <div className="item-name text-center">
                      <i className="flaticon-left-quote"></i>
                      <div className="testimonial-img m-auto">
                        <img src={testimonial.img} alt="Testimonial Images" />
                      </div>

                      <h5>{testimonial.name}</h5>
                      <span>{testimonial.designation}</span>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section className="news-section pt_100 pb_65">
            <div className="auto-container">
                  <h1 className="text-center service-head">Our Services</h1>
                <div className="row clearfix">
                    {newsItems1.map((item, index) => (
                        <div className="col-lg-4 col-md-6 col-sm-12 news-block" key={index}>
                            <div className="news-block-one wow fadeInUp animated" data-wow-delay={`${index * 300}ms`} data-wow-duration="1500ms">
                                <div className="inner-box myinnerbox">
                                    <figure className="image-box">
                                        <a href={item.link}><img src={item.image} alt={item.title} /></a>
                                    </figure>
                                    <div className="lower-content service-background">
                                        
                                        <h3><a href={item.link}>{item.title}</a></h3>
                                        <p>{item.para}</p>
                                        
                                        <div className="read-btn">
                                        <a href={item.link}className="">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

        </>
    )
}