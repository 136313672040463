import { useState } from "react";
import './Form.css';
import { Helmet } from "react-helmet";
import Swal from 'sweetalert2';

export default function Contact(){
    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        formData.append("access_key", "6952ef4e-8ed4-4106-88e0-9042e4950978");
    
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
    
        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: json
        }).then((res) => res.json());
    
        if (res.success) {
            Swal.fire({
              title: "Success",
              text: "Message Sent Successfully",
              icon: "success"
            });
          }
          event.target.reset();
      };
   
     const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    numberOfDays: '',
    pickUpPoint: '',
    dropoffLocation: '',
    selectedCab: '',
    selectedDate: '',
    message: ''
});

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
};

const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
    console.log(formData);
};
const schemaData = {
    "@context": "https://schema.org",
    "@type": "TravelAgency",
    "name": "Shiv Travels66",
    "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
    "url": "https://www.shivtravels66.com",
    "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
        "addressLocality": "Kirtane Baug Hadpasar",
        "addressRegion": "Maharashtra",
        "postalCode": "411036",
        "addressCountry": "India"
    },
    "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 9764768866",
        "contactType": "Customer Service",
        "availableLanguage": "English"
    },
    "email": "booking@shivtravels66.com",
    telephone:"+91 9764768866",
    image: "https://www.shivtravels66.com/OG Image-01.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "4.8",
      ratingValue: "3",
      worstRating: "1",
    },
    "keywords": [
      "Urbania On Rent in Pune",
      "Pune to Mahabaleshwar Urbania on Rent",
      "Pune to Shirdi Urbania on Rent",
      "Urbania Hire for Outstation Pune",
      "Urbania Hire for Corporate Events in Pune"
  ]

};

    return(
        <>
          <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Contact|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages starting at ₹299." />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and realx free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing beach vacation with our exclusive packages." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
          {/* <!-- page-title --> */}
        <section class="page-title centred contact-bg">
            <div class="bg-layer"></div>
            <div class="auto-container">
                <div class="content-box">
                    <span>Contact</span>
                    <h1>Contact Us</h1>
                </div>
            </div>
        </section>
        {/* <!-- page-title end --> */}


        {/* <!-- contact-info-section --> */}
        <section className="pricing-section pt_40 pb_110">
      <div className="row container">
        <div className="col-12 col-md-6">
        <div className="info-section">
              
                <div className="">
                <h3 className="pb-3 pt-3">Contact Information</h3>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

                <div className="text-center p-3 mb-3">
                <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2989.9702679499032!2d73.9347374!3d18.5276986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c174a3bebeed%3A0xd70f06012dc8b647!2sHermosa%20Casa%20By%20Sukhwani!5e1!3m2!1sen!2sin!4v1729850239456!5m2!1sen!2sin"
        width="100%"
        height="150"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      ></iframe>
    </div>
                </div>

             
                </div>
              </div>
            </div>
        </div>
        <div className="col-12 col-md-6">
        <div className="enquiry-container">
           

           <form onSubmit={onSubmit} className="enquiry-form">
            

               <div className="form-row">
                   <label>Your Full Name:</label>
                   <input
                       type="text"
                       name="fullName"
                       required
                   />
               </div>

               <div className="form-row">
                   <label>Your Email:</label>
                   <input
                       type="email"
                       name="email"
                       required
                   />
               </div>

               <div className="form-row">
                   <label>Your Phone:</label>
                   <input
                       type="tel"
                       name="phone"
                       required
                   />
               </div>

               <div className="form-row">
                   <label>Number of Days:</label>
                   <input
                       type="number"
                       name="numberOfDays"
                       required
                   />
               </div>

               <div className="form-row">
                   <label>Pick Up Point:</label>
                   <input
                       type="text"
                       name="pickUpPoint"
                       required
                   />
               </div>

               <div className="form-row">
                   <label>Dropoff Location:</label>
                   <input
                       type="text"
                       name="dropoffLocation"
                       required
                   />
               </div>

               <div className="form-row">
                   <label>Select Cabs:</label>
                   <select
                       name="selectedCab"
                       required
                   >
                       <option value="Urbania">Urbania</option>
                       <option value="Tempo Traveller">Tempo Traveller</option>
                       <option value="Innova Crysta">Innova Crysta</option>
                    
                   </select>
               </div>

               <div className="form-row">
                   <label>Select the Date:</label>
                   <input
                       type="date"
                       name="selectedDate"
                       required
                   />
               </div>

               <div className="form-row full-width">
                   <label>Message:</label>
                   <textarea
                       name="message"
                       required
                   />
               </div>
                
               <div className="form-row full-width">
                <button type="submit" className="submit-button">Submit</button>
             </div>
           </form>
           
       </div>
       
        </div>
      </div>
      </section>

        </>
    )
}