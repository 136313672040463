import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function Punetoshirdiurbaniaonrent() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "I recently booked the Urbania for our family trip to Shirdi with Shiv Travels66, and I couldn't be happier with the experience! The bus was spacious and comfortable, making the long journey enjoyable for everyone. Our driver was punctual and very knowledgeable about the route, ensuring we arrived safely and on time. I highly recommend Shiv Travels66 for anyone looking to travel to Shirdi in comfort and style!" ,
    name: "– Mrs. Anjali Deshmukh"
  },
  {
    quote: "We hired the Urbania from Shiv Travels66 for our pilgrimage to Shirdi, and it was a fantastic decision! The bus was well-maintained and equipped with all the amenities we needed for a comfortable ride. The booking process was seamless, and the staff was very helpful throughout. Thank you, Shiv Travels66, for making our journey memorable! We'll definitely choose you again for our future trips.",

    name: "- Mr. Rajesh Patil"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            "question": "What is the seating capacity of the Urbania bus?",
            "answer": "Our Force Urbania buses typically have a seating capacity of up to 17 passengers, making them perfect for family trips or small group outings to Shirdi."
          },
          {
            "question": "What amenities are included in the Urbania bus?",
            "answer": "Our Urbania buses come equipped with air conditioning, comfortable seating, charging ports, entertainment systems, and ample luggage space to ensure a pleasant journey."
          },
          {
            "question": "How do I book the Urbania for my trip to Shirdi?",
            "answer": "You can easily book the Urbania by contacting us through our website or by calling our customer service number. Provide your travel details, and we will assist you with the booking process."
          },
          {
            "question": "Are there any additional charges I should be aware of?",
            "answer": "We provide transparent pricing with no hidden fees. Any additional charges, such as for extra stops or extended hours, will be discussed and agreed upon during the booking process."
          },
          {
            "question": "Is there a cancellation policy?",
            "answer": "Yes, we have a cancellation policy in place. Please check our website or contact our customer service team for detailed information on cancellation terms and conditions."
          },
          {
            "question": "Can I customize my itinerary for the trip?",
            "answer": "Absolutely! We offer customizable itineraries based on your preferences. Whether you want to make extra stops along the way or visit nearby attractions, we can accommodate your requests."
          },
          {
            "question": "What are the payment options available?",
            "answer": "We offer various payment options, including cash, credit/debit cards, and online payment methods. You can choose the option that is most convenient for you during the booking process."
          },
          {
            "question": "How long does the journey from Pune to Shirdi take?",
            "answer": "The journey from Pune to Shirdi typically takes around 5 to 6 hours, depending on traffic conditions and the chosen route. Our drivers will ensure a timely arrival at your destination."
          },
          {
            "question": "What should I do if I have a special request (e.g., child seats or accessibility needs)?",
            "answer": "Please inform us of any special requests during the booking process. We strive to accommodate all passenger needs, including child seats and accessibility requirements."
          },
          {
            "question": "Is your service available for other destinations as well?",
            "answer": "Yes, in addition to Pune to Shirdi, we offer Urbania rentals for various destinations, including other pilgrimage sites and tourist attractions in Maharashtra. Feel free to inquire about our services for your desired location!"
          }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com/Pune-to-Shirdi-Urbania-on-Rent",
      "logo": "https://www.shivtravels66.com/img/Pune-to-Shirdi-Urbania-on-Rent.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  

   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];

  



   
  return (
    <>
    <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/img/Pune-to-Shirdi-Urbania-on-Rent.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/Pune-to-Shirdi-Urbania-on-Rent" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
     
      {/* <!-- page-title --> */}
      <section class="page-title centred car-img2">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Pune to Shirdi Urbania on Rent</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Pune-to-Shirdi-Urbania-on-Rent.jpg" alt="Urbania on rent in Pune" />
              </div>

              <div>
              <h1>Pune to Shirdi Urbania on Rent with Shiv Travels66</h1>
      <p>
        Look no further than Shiv Travels66 for your transportation needs! Our Pune to Shirdi Urbania on rent service offers a luxurious and comfortable travel experience that makes your journey as memorable as your destination.
      </p>
      
      <h2>Force Urbania Luxury Mini Bus on Rent</h2>
      <p>
        Looking for a Force Urbania luxury mini bus on rent? Shiv Travels66 provides modern and comfortable Urbania buses for all your travel needs. Perfect for group outings, corporate events, or family trips, our luxury mini buses offer spacious seating, air conditioning, and entertainment options. Experience a premium travel experience with us. Contact us today to book your Force Urbania!
      </p>
      
      <h2>Hire Urbania Bus on Rent with Shiv Travels</h2>
      <p>
        When you need to hire an Urbania bus on rent, Shiv Travels66 is your trusted partner. We offer a range of Urbania buses that cater to various group sizes, ensuring comfort and convenience throughout your journey. Our professional drivers ensure a smooth ride, allowing you to focus on your travels. Whether it’s a local outing or an outstation trip, we have the perfect Urbania bus for you. Get in touch to reserve your bus today!
      </p>
      
      <h2>Pune Airport to Shirdi Urbania on Rent</h2>
      <p>
        Traveling from Pune Airport to Shirdi? Choose our Urbania on rent service for a comfortable and hassle-free journey. Our experienced drivers will pick you up at the airport and take you directly to Shirdi, ensuring you have a relaxing start to your trip. With spacious seating and luxury amenities, our Urbania buses provide a perfect travel experience. Book your Pune Airport to Shirdi Urbania today!
      </p>
      
      <h2>Pune to Bhimashankar Urbania Bus on Rent</h2>
      <p>
        Planning a trip to Bhimashankar? Our Pune to Bhimashankar Urbania bus on rent service offers a convenient and luxurious way to travel. Enjoy the scenic route with friends and family while our professional drivers handle the journey. With comfortable seating and modern facilities, our Urbania buses make your trip enjoyable and stress-free. Contact Shiv Travels66 to book your Urbania today!
      </p>
      
      <h2>Pune to Shani Singapura Urbania On Rent</h2>
      <p>
        Experience the spiritual journey from Pune to Shani Singapura with our Urbania on rent service. Shiv Travels66 provides comfortable Urbania buses for a smooth ride to one of Maharashtra’s most revered temples. Our buses are equipped with all necessary amenities, ensuring you have a pleasant experience. Book your Pune to Shani Singapura Urbania today and travel in comfort!
      </p>
      
      <h2>Pune to Aurangabad Urbania on Rent</h2>
      <p>
        Visiting Aurangabad? Choose our Pune to Aurangabad Urbania on rent service for a luxurious and comfortable travel experience. With spacious seating and air conditioning, our Urbania buses are ideal for long journeys. Our experienced drivers know the best routes and will ensure you reach Aurangabad safely and on time. Contact Shiv Travels66 to reserve your Urbania bus for this journey!
      </p>
      
      <h2>Pune to Nashik Urbania Bus on Rent</h2>
      <p>
        Explore the beautiful city of Nashik with our Pune to Nashik Urbania bus on rent service. Our luxury Urbania buses are perfect for group travels, offering comfort and space for everyone. Whether you’re visiting vineyards or temples, our drivers will take you to your destination safely and efficiently. Book your Pune to Nashik Urbania bus today with Shiv Travels66!
      </p>
      
      <h2>Pune to Ajanta Ellora Urbania Bus on Rent</h2>
      <p>
        Experience the historical marvels of Ajanta and Ellora with our Pune to Ajanta Ellora Urbania bus on rent. Our comfortable Urbania buses provide a perfect blend of luxury and convenience for this memorable journey. With knowledgeable drivers and well-maintained vehicles, we ensure you have a delightful travel experience. Contact Shiv Travels66 to book your Urbania for this incredible trip!
      </p>
      
      <h2>Pune to Trimbakeshwar Urbania Bus on Rent</h2>
      <p>
        Opt for our Pune to Trimbakeshwar Urbania bus on rent service for a comfortable and convenient journey. Our spacious and luxurious Urbania buses are perfect for group travel, ensuring a smooth ride to this sacred site known for its historic temple dedicated to Lord Shiva. With our experienced drivers, you can sit back and enjoy the scenic landscapes along the route. Book your Urbania bus today for a hassle-free pilgrimage!
      </p>
      
      <h2>Pune to Grishneshwar Urbania Bus on Rent</h2>
      <p>
        Make your pilgrimage to Grishneshwar, one of the 12 Jyotirlingas, memorable with our Pune to Grishneshwar Urbania bus on rent service. Our luxury Urbania buses provide ample space and comfort for your journey. Equipped with modern amenities, they ensure a pleasant travel experience for you and your family. Let our professional drivers navigate the way while you focus on your spiritual journey. Contact Shiv Travels66 to reserve your Urbania for this sacred trip!
      </p>
      
      <h2>Pune to Ashtavinayak Urbania Tour Package from Pune</h2>
      <p>
        Embark on a spiritual journey with our Pune to Ashtavinayak Urbania tour package! This package covers all Ashtavinayak temples, offering a comprehensive experience for devotees. Our comfortable Urbania buses are perfect for group outings, providing luxury and space for everyone. With flexible itineraries and knowledgeable drivers, you can explore these sacred sites at your own pace. Book your Ashtavinayak tour package with Shiv Travels66 today for an unforgettable pilgrimage!
      </p>
      
      <h2>Pune to 5 Jyotirlinga Darshan Urbania on Rent</h2>
      <p>
        Experience the divine with our Pune to 5 Jyotirlinga Darshan Urbania on rent service. This tour allows you to visit five of the most revered Jyotirlingas in Maharashtra. Our luxurious Urbania buses ensure comfort during this extensive journey, featuring spacious seating and modern amenities. With professional drivers familiar with the route, you can focus on your spiritual experience while we take care of your travel needs. Contact Shiv Travels66 to book your Urbania for this sacred journey today!
      </p>
              </div>

            

              <div>
      <h1>Pune to Shirdi Urbania on Rent with Shiv Travels66 Contact Number

</h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Pune to Shirdi Urbania on Rent. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Force Urbania Luxury Mini Bus On Rent</p>
            <p>- Hire Urbania bus on Rent with Shiv Travels</p>
            <p>- Pune Airport to Shirdi Urbania on Rent</p>
            <p>- Pune to Bhimashankar Urbania Bus on Rent</p>
            <p>- Pune to Shani Shingnapur Urbania On Rent</p>
            <p>- Pune to Aurangabad Urbania on Rent</p>
        
            <p>- Pune to 5 Jyotirlinga Darshan Urbania on Rent</p>
         
          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- Pune to Nashik Urbania Bus on Rent</p>
            <p>- Pune to Ajanta Ellora Urbania Bus on Rent</p>
            <p>- Pune to Trimbakeshwar Urbania Bus on Rent</p>
            <p>- Pune to Grishneshwar Urbania Bus on Rent</p>
            <p>- Pune to Ashtavinayak Urbania Tour Package from Pune</p>
            </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
            <h1>Why Choose Us for Pune to Shirdi Urbania on Rent with Shiv Travels66?</h1>
            <ol>
                <li>
                    <h2>Comfortable and Spacious Vehicles</h2>
                    <p>Our Force Urbania buses are designed to provide maximum comfort for passengers. With ample seating and spacious interiors, you can enjoy a relaxed journey from Pune to Shirdi, whether traveling with family or a larger group.</p>
                </li>
                <li>
                    <h2>Luxury Amenities</h2>
                    <p>We prioritize your comfort and convenience. Our Urbania vehicles come equipped with modern amenities, including air conditioning, entertainment systems, charging ports, and clean interiors. Experience a smooth ride that makes your pilgrimage enjoyable.</p>
                </li>
                <li>
                    <h2>Experienced Drivers</h2>
                    <p>Safety is our top concern. Our professional drivers are not only skilled and experienced but also knowledgeable about the best routes to Shirdi. They ensure a safe and timely journey, allowing you to focus on your spiritual experience.</p>
                </li>
                <li>
                    <h2>Affordable Pricing</h2>
                    <p>At Shiv Travels66, we believe in providing high-quality services at competitive prices. Our Pune to Shirdi Urbania on rent packages are designed to fit various budgets, ensuring you get the best value for your money without compromising on quality.</p>
                </li>
                <li>
                    <h2>Customizable Itineraries</h2>
                    <p>Every trip is unique, and we understand that. We offer customizable itineraries that allow you to choose your stops and travel duration based on your preferences. Whether you want to explore nearby attractions or make special stops, we can tailor the journey to your needs.</p>
                </li>
                <li>
                    <h2>24/7 Customer Support</h2>
                    <p>Our commitment to customer satisfaction extends beyond booking. We provide 24/7 customer support to assist you with any queries or emergencies during your trip. Our team is always available to help, ensuring a worry-free experience.</p>
                </li>
                <li>
                    <h2>Flexible Booking Options</h2>
                    <p>We offer flexible booking options to cater to your specific travel needs. Whether you need the Urbania for a day or several days, our team can accommodate your requirements.</p>
                </li>
                <li>
                    <h2>Positive Customer Reviews</h2>
                    <p>Don’t just take our word for it—check out our customer testimonials! Our satisfied clients rave about their experiences with Shiv Travels66, highlighting our professionalism, comfort, and reliability.</p>
                </li>
                <li>
                    <h2>Commitment to Safety</h2>
                    <p>We adhere to all safety regulations and ensure that our vehicles are regularly maintained and sanitized. Your safety is our priority, and we take all necessary precautions to provide a secure travel experience.</p>
                </li>
                <li>
                    <h2>Convenient Booking Process</h2>
                    <p>Booking your Pune to Shirdi Urbania is simple! Just contact us via our website or call us directly, and our team will guide you through the process, ensuring a hassle-free experience from start to finish.</p>
                </li>
            </ol>
        </div>
      
    <div className="faq-container">
    <h2>FAQs for Shiv Travels66 “Pune to Shirdi Urbania on Rent"?</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 - Urbania on Rent in Pune</h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>

          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
