import React from 'react';



const Gallery = () => {
    const images = [
       'real/gallery/innova-crysta.jpg',
       'real/gallery/innova-crysta1.jpg',
       'real/gallery/shiv-travel-kokan.jpg',
       'real/gallery/shiv-travels-crysta.jpg',
       'real/gallery/shiv-travels-customer4.jpg',
       'real/gallery/shiv-travels-driver.jpg',
       'real/gallery/shiv-travels-tempo-traveller.jpg',
        'real/gallery/shivtravel-customer.jpg',
        'real/gallery/shivtravel-customer1.jpg',
        'real/gallery/shivtravel-customer2.jpg',
        'real/gallery/shivtravel-customer3.jpg',
        'real/gallery/shivtravel-img2.jpg',
        'real/gallery/shivtravels-drivers.jpg',
        'real/gallery/shivtravels-front.jpg',
        'real/gallery/shivtravels-innovacrysta.jpg',
        'real/gallery/shivtravels-tempotravellers1.jpg',
        
      ];
      

      const cards = [
        {  img: "real/card/collection.JPG" },
        { img:'real/card/gallery7.JPG' },
        { img: "real/card/group1.JPG" },
        {img:'real/card/group2.JPG' },
        {img:'real/card/group3.JPG'},
        {img:'real/card/seates.jpeg'},
    
        {img:'real/card/tempo trav1.jpeg'},
        {img:'real/card/tempo traveller.jpeg'},
        {img:'real/card/urbani1.JPG'},
        {img:'real/card/urbania seates.JPG'}
    ];
    
  return (
         <>

           {/* <!-- page-title --> */}
      <section class="page-title centred about-section">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Gallery</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

<div className="gallery">
         
         {images.map((img, index) => (
             <div className="gallery-item" key={index}>
                
                 <img src={img} alt={`Gallery item ${index + 1}`} />
                
             </div>
         ))}
     </div>

     <h2 className='text-center'><span className='colorblue'>Our Services</span></h2>
     <div className="carousel1">
           
            <div className="carousel-inner1">
                {cards.map((card, index) => (
                    <div className="carousel-item1" key={index}>
                       <img src={card.img} alt="img"/>
                    </div>
                ))}
            </div>
        </div>

            
         </>
  );
};

export default Gallery;
