import React from 'react';
import { Helmet } from 'react-helmet';

const InnovaCrysta = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '50px 20px',
      backgroundColor: '#f9f9f9',
      borderRadius: '10px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      margin: '20px',
      flexWrap: 'wrap', // Allow wrapping for smaller screens
    },
    content: {
      flex: 1,
      padding: '20px',
      maxWidth: '600px',
      flexBasis: '45%', // Default basis for larger screens
    },
    heading: {
      fontSize: '2.5em',
      marginBottom: '20px',
      fontFamily: '"Georgia", serif',
      color: '#333',
    },
    text: {
      fontSize: '1.2em',
      lineHeight: '1.6',
      color: '#555',
    },
    image: {
      width: '100%', // Make image responsive
      maxWidth: '600px',
      height: 'auto',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      marginLeft: '20px',
    },
    // Responsive Styles
    '@media (max-width: 768px)': {
      container: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      content: {
        flexBasis: '100%', // Full width on smaller screens
        textAlign: 'center', // Center text for smaller screens
      },
      image: {
        marginLeft: '0', // Reset margin for smaller screens
        marginTop: '20px', // Add top margin for spacing
      },
    },
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "TravelAgency",
    "name": "Shiv Travels66",
    "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
    "url": "https://www.shivtravels66.com",
    "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
        "addressLocality": "Kirtane Baug Hadpasar",
        "addressRegion": "Maharashtra",
        "postalCode": "411036",
        "addressCountry": "India"
    },
    "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 9764768866",
        "contactType": "Customer Service",
        "availableLanguage": "English"
    },
    "email": "booking@shivtravels66.com",
    telephone:"+91 9764768866",
    image: "https://www.shivtravels66.com/OG Image-01.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "4.8",
      ratingValue: "3",
      worstRating: "1",
    },
    "keywords": [
      "Urbania On Rent in Pune",
      "Pune to Mahabaleshwar Urbania on Rent",
      "Pune to Shirdi Urbania on Rent",
      "Urbania Hire for Outstation Pune",
      "Urbania Hire for Corporate Events in Pune"
  ]

};


  

  return (
    <>
        <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Innova on Rent in Pune |  Tempo Traveller Hire for Wedding in Pune
   | Mini Bus On Rent in Pune
| Innova Crysta on Rent in Pune
|Pune to Goa Urbania On Rent- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content="  Tempo Traveller Hire for Wedding in Pune, Mini Bus On Rent in Pune, Innova Crysta on Rent in Pune,Pune to Goa Urbania On Rent" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
      {/* <!-- page-title --> */}
      <section class="page-title centred fleet-section1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Innova Crysta</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}
  
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.heading}><span className='colorblue'>Innova Crysta</span></h1>
        <p style={styles.text}>
          The Innova Crysta is a versatile and spacious MPV that offers exceptional comfort and advanced features for both driver and passengers. With its premium interior and powerful engine options, the Innova Crysta is perfect for family outings and long journeys.
        </p>
        <h2 style={styles.heading}><span className='colorblue'>Key Features:</span></h2>
        <ul style={styles.text} className='mylist' >
          <li>Elegant and modern design with a spacious cabin</li>
          <li>Advanced safety features, including multiple airbags and ABS</li>
          <li>Powerful diesel and petrol engine options</li>
          <li>Comfortable seating with multiple configurations</li>
          <li>State-of-the-art infotainment system with navigation</li>
        </ul>
      </div>
      <div className='crystaimg'>
      <img
        src="real/gallery/innova-crysta1.jpg"  // Replace with your image URL
        alt="Innova Crysta"
        style={styles.image}
      />
  
      </div>
     
    </div>
    </>
  );
};

export default InnovaCrysta;
