import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function PunetoMumbai() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "I recently booked the Force Urbania for a trip from Pune to Mumbai with Shiv Travels66, and I couldn't be happier! The vehicle was spacious, clean, and well-maintained. Our driver was professional and knowledgeable about the best routes, ensuring we reached our destination on time. The journey was comfortable and enjoyable for everyone in our group. I highly recommend Shiv Travels66 for anyone looking for reliable transportation!",
    name: "Mr. Rahul S."
  },
  {
    quote:"Our family took a weekend trip from Pune to Mumbai using Shiv Travels66's Urbania rental service, and it was fantastic! The booking process was easy, and the customer service team was very helpful in answering all our questions. The Urbania was perfect for our group of 15, with plenty of room for our luggage. The trip was smooth, and we had a wonderful time exploring Mumbai. I will definitely be using Shiv Travels66 again for future trips!",
    name: "Mr.Priya M."
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            "question": "What is Shiv Travels66?",
            "answer": "Shiv Travels66 is a trusted travel service provider offering comfortable and reliable Urbania rentals for trips between Pune and Mumbai."
        },
        {
            "question": "How can I book the Pune to Mumbai Urbania on rent?",
            "answer": "You can book by contacting us at +91 9764768866 or by visiting our website to fill out the inquiry form."
        },
        {
            "question": "What is the capacity of the Force Urbania?",
            "answer": "The Force Urbania can comfortably seat up to 17 passengers, making it ideal for group travel."
        },
        {
            "question": "What amenities are included in the Urbania rentals?",
            "answer": "Our Urbania vehicles are equipped with air conditioning, entertainment systems, charging points, and spacious seating for a comfortable journey."
        },
        {
            "question": "Are your drivers experienced?",
            "answer": "Yes, all our drivers are experienced and knowledgeable about the best routes from Pune to Mumbai, ensuring a smooth and timely journey."
        },
        {
            "question": "Is there a minimum rental period for the Urbania?",
            "answer": "Typically, our minimum rental period is one day. However, we can customize packages based on your specific needs."
        },
        {
            "question": "How much does it cost to rent an Urbania for a trip to Mumbai?",
            "answer": "Pricing varies based on factors like distance, duration, and additional services. Please contact us for a detailed quote."
        },
        {
            "question": "Can I customize my itinerary?",
            "answer": "Absolutely! We offer flexible itineraries to accommodate your travel plans and preferences."
        },
        {
            "question": "Do you provide service for airport transfers?",
            "answer": "Yes, we offer Pune to Mumbai airport Urbania bus rentals, ensuring timely airport transfers for your convenience."
        },
        {
            "question": "What should I do if I need to cancel my booking?",
            "answer": "Please contact us as soon as possible. Our cancellation policy will be provided at the time of booking to ensure you understand the terms."
        }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com/Pune-to-Mumbai-Urbania-on-Rent",
      "logo": "https://www.shivtravels66.com/img/Pune to Mumbai.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/img/Pune to Mumbai.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  

   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];
  

   
  return (
    <>

<Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/img/Pune to Mumbai.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/Pune-to-Mumbai-Urbania-on-Rent" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>

    

     
      {/* <!-- page-title --> */}
      <section class="page-title centred background1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Pune to Mumbai Urbania</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page ">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Pune to Mumbai.jpg" alt="Pune to mumbai urbania" />
              </div>

             <div>
             <h1>Pune to Mumbai Urbania on Rent with Shiv Travels66</h1>

<p>Look no further than Shiv Travels66 for your Pune to Mumbai Urbania on rent needs. We specialize in providing comfortable, reliable, and affordable Urbania rentals, ensuring a smooth travel experience for individuals, families, and corporate groups alike.</p>

<h2>Affordable Travel from Pune to Mumbai</h2>
<p>At Shiv Travels66, we believe in providing affordable travel from Pune to Mumbai without compromising on quality. Our transparent pricing structure ensures you know exactly what you’re paying for, with no hidden costs. Enjoy the best value for your money as you travel in style.</p>

<h2>Contact Us for Pune to Mumbai Urbania on Rent</h2>
<p>To book your Pune to Mumbai Urbania on rent, simply reach out to us at <strong>+91 9764768866</strong>. Our friendly team is available to assist you with your booking and answer any questions you may have.</p>

<h2>Finding Urbania Rentals Near You</h2>
<p>If you're searching for Force Urbania on rent near me, look no further! Shiv Travels66 provides convenient rental options across various locations, ensuring you can easily find the right vehicle for your needs.</p>

<h2>Force Urbania 17 Seater for Rent</h2>
<p>Our Force Urbania 17 seater for rent is ideal for larger groups. Whether it’s a family outing, corporate event, or a trip with friends, this vehicle offers the space and comfort required for a fantastic travel experience.</p>

<h2>Explore with Our Pune to Mumbai Tour Package – 3 Days</h2>
<p>Enhance your travel with our Pune to Mumbai tour package for 3 days. This package includes:</p>
<ul>
    <li><strong>Accommodation:</strong> Stay in well-reviewed hotels that cater to your comfort.</li>
    <li><strong>Sightseeing:</strong> Visit iconic attractions such as the Gateway of India, Marine Drive, and more.</li>
    <li><strong>Customizable Itinerary:</strong> Tailor the tour according to your preferences, ensuring you experience everything Mumbai has to offer.</li>
</ul>

<h2>Convenient Urbania Rentals Near You</h2>
<p>Searching for Pune to Mumbai Urbania on rent for trip near me? Shiv Travels66 is your go-to choice! We provide convenient rental options across Pune, including Urbania on rent in Viman Nagar. With our easy booking process, you can find the perfect vehicle for your travel needs right in your neighborhood.</p>

<h2>Why Choose Our Urbania Rentals?</h2>
<ul>
    <li><strong>Spacious and Comfortable:</strong> Our Force Urbania is designed to comfortably accommodate groups, providing ample space for passengers and luggage.</li>
    <li><strong>Modern Amenities:</strong> Enjoy features such as air conditioning, entertainment systems, and charging points, making your travel experience enjoyable.</li>
    <li><strong>Professional Drivers:</strong> Our experienced drivers know the best routes, ensuring a smooth and efficient journey from Pune to Mumbai.</li>
</ul>

<h2>Pune to Mumbai Airport Urbania Bus on Rent</h2>
<p>Need to catch a flight? Our Pune to Mumbai airport Urbania bus on rent is the perfect solution. With reliable and timely service, we ensure you arrive at the airport with plenty of time to spare. Book your ride today for a stress-free airport transfer.</p>

<h2>Hire Urbania Bus on Rent with Shiv Travels66</h2>
<p>When you hire Urbania bus on rent with Shiv Travels, you get more than just a vehicle. You receive a full travel experience designed around your needs. Our customer support team is here to help you every step of the way, ensuring your trip is smooth and enjoyable.</p>

<h2>Pune to Dadar Urbania on Rent</h2>
<p>If you’re planning to visit Dadar, we also offer Pune to Dadar Urbania on rent. Whether it’s for a family function, a wedding, or simply to explore the vibrant city, our Urbania buses are ready to serve you.</p>

<h2>Force Urbania 10 Seater on Rent in Pune</h2>
<p>Traveling with a smaller group? Our Force Urbania 10 seater on rent in Pune is perfect for intimate trips. Enjoy a cozy ride without sacrificing comfort, making it an ideal choice for small family outings or business trips.</p>

             </div>

    

              <div>
      <h1>Pune to Mumbai Urbania with Shiv Travels66 Contact Number: </h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Pune to Mumbai Urbania on rent.</p>
<p>- Affordable travel Pune to Mumbai.</p>
<p>- Pune to Mumbai Urbania on Rent Contact Number.</p>
<p>- Force Urbania on Rent Near me.</p>
<p>- Force Urbania 17 Seater for Rent.</p>
<p>- Pune to Mumbai Tour package 3 day.</p>


    


          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- Pune to Mumbai urbania on rent for trip near me.</p>
<p>- Urbania On Rent in Viman Nagar.</p>
<p>- Pune to Mumbai airport urbania bus on rent.</p>
<p>- Hire Urbania bus on Rent with Shiv Travels.</p>
<p>- Pune to Dadar urbania on rent.</p>
<p>- Force Urbania 10 seater on rent in Pune.</p>
<p>- Pune to Mumbai Urbania on Rent for trip.</p>


          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h1>Why Choose Us for Pune to Mumbai urbaia with Shiv Travels66?</h1>
   
<ul>
    <li><strong>Comfort and Space:</strong> Our Force Urbania vehicles are designed for maximum comfort, providing ample space for passengers and luggage, making your journey enjoyable.</li>
    <li><strong>Affordable Pricing:</strong> We offer competitive rates without compromising on quality. Our transparent pricing ensures no hidden fees, giving you the best value for your money.</li>
    <li><strong>Experienced Drivers:</strong> Our professional drivers are familiar with the best routes and traffic patterns, ensuring a smooth and timely journey from Pune to Mumbai.</li>
    <li><strong>Modern Amenities:</strong> Enjoy a range of modern amenities, including air conditioning, entertainment systems, and charging ports, enhancing your travel experience.</li>
    <li><strong>Flexible Booking Options:</strong> We offer convenient booking options tailored to your needs, whether it’s a one-way trip or a round trip, with flexible scheduling.</li>
    <li><strong>Excellent Customer Service:</strong> Our dedicated team is available to assist you at every step, from booking to drop-off, ensuring your trip goes smoothly.</li>
    <li><strong>Customized Travel Packages:</strong> We offer customizable tour packages that can include sightseeing and accommodation, allowing you to experience the best of Mumbai.</li>
</ul>


    </div>
      
    <div className="faq-container">
    <h2>FAQs for Shiv Travels66 regarding Pune to Mumbai with Urbania services ?</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 regarding the Pune to Mumbai urbania service:</h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
            {/* ========= */}
          </div>
        </div>
      </section>
    </>
  );
}
