import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function TempoTravellerOnRentInPune() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "I recently hired a Tempo Traveller from Shiv Travels66 for a family trip to Mahabaleshwar, and I couldn't be happier with the service! The vehicle was clean, comfortable, and spacious enough for all of us. Our driver was punctual, friendly, and knowledgeable about the best routes. The entire experience was hassle-free, and I highly recommend Shiv Travels66 for anyone looking to rent a vehicle in Pune!",
    name: "Mrs. Priya Deshmukh"
  },
  {
    quote: "Shiv Travels66 provided an excellent Tempo Traveller for our corporate retreat. The booking process was straightforward, and the team was very accommodating with our specific requests. The vehicle was in great condition, and our driver ensured we arrived at each destination safely and on time. I will definitely choose Shiv Travels66 for our future travel needs!",
    name: "- Mr. Rohan Patil"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            question: "What types of Tempo Travellers are available for rent with Shiv Travels66?",
            answer: "We offer a variety of Tempo Travellers, including 12-seater, 13-seater, 14-seater, 17-seater, and 20-seater options. Each vehicle is designed for comfort and convenience to meet your group travel needs."
        },
        {
            question: "How do I book a Tempo Traveller with Shiv Travels66?",
            answer: "You can easily book a Tempo Traveller by contacting our customer service team via phone or through our website. Simply provide your travel details, and we will assist you with the booking process."
        },
        {
            question: "What are the rates for renting a Tempo Traveller in Pune?",
            answer: "Our rates vary depending on the type of vehicle, rental duration, and destination. We provide transparent pricing with no hidden charges. For a detailed quote, please contact our team with your travel itinerary."
        },
        {
            question: "Are drivers provided with the Tempo Traveller rental?",
            answer: "Yes, all our Tempo Traveller rentals come with experienced and professional drivers. They are knowledgeable about local routes and prioritize your safety and comfort during the journey."
        },
        {
            question: "Can I hire a Tempo Traveller for outstation trips?",
            answer: "Absolutely! We offer Tempo Travellers for both local and outstation trips. Whether you’re planning a family vacation or a corporate event, we have the right vehicle for your needs."
        },
        {
            question: "Is there a minimum rental duration for the Tempo Traveller?",
            answer: "We typically have a minimum rental duration of 8 hours for local trips. For outstation trips, the duration may vary based on the destination and itinerary. Please inquire for specifics."
        },
        {
            question: "Are there any additional charges for long-distance travel?",
            answer: "Additional charges may apply for long-distance travel, including tolls, parking fees, and driver allowances. We will provide a detailed breakdown of any extra costs in your quote."
        },
        {
            question: "Can I request specific amenities for the Tempo Traveller?",
            answer: "Yes, we aim to accommodate your needs. If you have specific requests, such as Wi-Fi, entertainment systems, or refreshments, please let us know at the time of booking, and we will do our best to fulfill them."
        },
        {
            question: "What is the cancellation policy for Tempo Traveller rentals?",
            answer: "Our cancellation policy may vary based on the booking conditions. We recommend reviewing our policy at the time of booking or contacting our customer service for detailed information."
        },
        {
            question: "How can I contact Shiv Travels66 for more information?",
            answer: "You can reach us via phone, email, or through our website. Our customer support team is always ready to assist you with any questions or to help you book your Tempo Traveller."
        }
    ]

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com/Tempo-Traveller-On-Rent-in-Pune",
      "logo": "https://www.shivtravels66.com/img/Tempo-Traveller On-Rent-in-Pune.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/img/Tempo-Traveller On-Rent-in-Pune.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  


   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];
  


   
  return (
    <>

<Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/img/Tempo-Traveller On-Rent-in-Pune.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/Tempo-Traveller-On-Rent-in-Pune" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>

     
      {/* <!-- page-title --> */}
      <section class="page-title centred background1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travles66</span>
            <h1>Tempo Traveller on Rent in Pune</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Tempo-Traveller On-Rent-in-Pune.jpg" alt="Tempo Traveller on rent in Pune" />
              </div>

              <div>
              <h1>Tempo Traveller on Rent in Pune with Shiv Travels66</h1>
<p>When it comes to group travel in Pune, nothing beats the convenience and comfort of a Tempo Traveller. At Shiv Travels66, we offer a range of well-maintained Tempo Travellers for rent, perfect for family trips, corporate outings, weddings, and other events. Whether you're planning a weekend getaway or a long-distance journey, our Tempo Travellers provide the ideal solution for comfortable group travel.</p>

<h2>17 Seater Tempo Traveller on Rent in Pune</h2>
<p>Our 17-seater Tempo Traveller is ideal for medium-sized groups. With comfortable seating, air conditioning, and ample space for luggage, you can travel without worrying about cramped conditions. Perfect for family trips or team outings, our 17-seater ensures a comfortable journey.</p>

<h2>12 Seater Tempo Traveller on Rent in Pune</h2>
<p>For smaller groups, our 12-seater Tempo Traveller offers a cozy and comfortable ride. Equipped with modern amenities, this vehicle is perfect for local sightseeing, picnics, or short trips around Pune. Enjoy the flexibility of traveling together with your group in style.</p>

<h2>20 Seater Tempo Traveller on Rent in Pune</h2>
<p>Planning a larger event? Our 20-seater Tempo Traveller is designed to accommodate big groups without compromising on comfort. Ideal for corporate events, weddings, or group travel to popular destinations, this vehicle ensures everyone arrives together and on time.</p>

<h2>13 Seater Tempo Traveller on Rent in Pune</h2>
<p>Our 13-seater Tempo Traveller is perfect for groups looking for a comfortable yet spacious travel option. With plush seating and air conditioning, it’s well-suited for day trips, corporate outings, and family vacations.</p>

<h2>Traveller on Rent Pune</h2>
<p>Looking for a reliable Traveller on rent in Pune? Shiv Travels66 offers a variety of Tempo Travellers to meet your specific needs. From short local trips to long-distance travels, we have the right vehicle for you.</p>

<h2>14 Seater Tempo Traveller on Rent in Pune</h2>
<p>Our 14-seater Tempo Traveller is another excellent choice for mid-sized groups. With a blend of comfort and functionality, it’s ideal for local tours, family gatherings, and corporate meetings, ensuring a pleasant ride for all passengers.</p>

<h2>Traveller Bus on Rent in Pune</h2>
<p>If you require a larger capacity vehicle, our Traveller bus on rent in Pune is perfect for bigger gatherings or events. Experience the convenience of traveling together while enjoying the amenities our buses have to offer.</p>

<h2>Pune Tempo Traveller Hire for Outstation</h2>
<p>Planning a trip outside Pune? Our Tempo Traveller hire for outstation journeys is the best choice for comfortable long-distance travel. Whether you’re heading to Mahabaleshwar, Lonavala, or other nearby destinations, we ensure a safe and enjoyable ride.</p>

<h2>Luxury Tempo Traveller on Rent in Pune</h2>
<p>For those seeking a premium experience, our luxury Tempo Traveller on rent in Pune is the perfect solution. With plush interiors, superior comfort, and added amenities, you can travel in style for any occasion, from weddings to corporate events.</p>

<h2>Pune to Mahabaleshwar Tempo Traveller</h2>
<p>Planning a trip from Pune to Mahabaleshwar? Our Tempo Traveller service provides a hassle-free way to explore this beautiful hill station. With comfortable seating and experienced drivers, you can enjoy the scenic beauty without any worries.</p>

<h2>Tempo on Rent in Pune</h2>
<p>Our Tempo on rent in Pune service caters to groups of all sizes. Whether you need a compact option for a small family or a larger vehicle for corporate events, we have a range of Tempo Travellers to fit your needs.</p>

<h2>Pune to Shirdi Tempo Traveller Rate</h2>
<p>Our Pune to Shirdi Tempo Traveller rate is competitive and includes all the amenities you need for a comfortable journey. Experience the convenience of traveling together without the hassle of multiple vehicles.</p>

<h2>Tempo Traveller 17-Seater on Rent in Pune</h2>
<p>For medium-sized groups, our Tempo Traveller 17 seater on rent in Pune is the perfect option. Enjoy spacious seating, air conditioning, and ample storage for luggage, making your journey comfortable and enjoyable.</p>

<h2>Tempo Traveller Booking in Pune</h2>
<p>Booking a Tempo Traveller with Shiv Travels66 is simple and hassle-free. You can easily make your Tempo Traveller booking in Pune through our website or by contacting our customer service team. We ensure a smooth booking experience tailored to your travel needs.</p>

<h2>Tempo Traveller from Pune to Mahabaleshwar</h2>
<p>Make your getaway to Mahabaleshwar memorable with our Tempo Traveller from Pune to Mahabaleshwar service. Enjoy the scenic beauty of the Western Ghats while traveling in comfort with friends and family. Our experienced drivers ensure a safe and enjoyable journey.</p>

<h2>Tempo Traveller Hire in Pune</h2>
<p>When you need a reliable vehicle for group travel, look no further than our Tempo Traveller hire in Pune service. We offer flexible rental options, whether for a day trip or longer excursions, ensuring your travel needs are met.</p>

<h2>Tempo Traveller Hire Pune</h2>
<p>With Tempo Traveller hire Pune, you can choose the vehicle that best suits your group size and travel plans. Our team is dedicated to providing excellent customer service, making your travel experience enjoyable from start to finish.</p>

<h2>Tempo Traveller on Rent in Pune Rate Per Km</h2>
<p>Understanding your travel budget is crucial. Our Tempo Traveller on rent in Pune rate per km is transparent and competitive. We provide a detailed quote based on your itinerary, ensuring no hidden fees for your journey.</p>

<h2>Tempo Traveller Rental Pune</h2>
<p>For those seeking Tempo Traveller rental in Pune, we offer a wide range of vehicles to choose from. Each Tempo Traveller is well-maintained and equipped with modern amenities to ensure a pleasant journey for you and your passengers.</p>

              </div>


              <div>
      <h1> Tempo Traveller On Rent in Pune with Shiv Travels66 Contact Number</h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Tempo Traveller On Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
         
          <p>- 17 Seater Tempo Traveller on Rent in Pune</p>
          <p>- 12 Seater Tempo Traveller on Rent in Pune</p>
          <p>- 20 Seater Tempo Traveller on Rent in Pune</p>
          <p>- 13 Seater Tempo Traveller on Rent in Pune</p>
           <p>- Traveller on Rent Pune</p>
           <p>- 14 Seater Tempo Traveller on Rent in Pune</p>
         

          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- Traveller Bus on Rent in Pune</p>
           <p>- Pune Tempo Traveller Hire for Outstation</p>
           <p>- Luxury Tempo Traveller on Rent in Pune</p>
           <p>- Pune to Mahabaleshwar Tempo Traveller</p>
           <p>- Tempo on Rent in Pune</p>
           <p>- Pune to Shirdi Tempo Traveller Rate</p>

          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h2>Why Choose Us for Tempo Traveller on Rent in Pune with Shiv Travels66?</h2>

<p>Choosing Shiv Travels66 for your Tempo Traveller on rent in Pune means opting for comfort, reliability, and exceptional service. Here’s why we stand out in the crowded transportation market:</p>

<ol>
    <li>
        <strong>Diverse Fleet of Vehicles:</strong>
        <p>We offer a wide range of Tempo Travellers to meet various needs, from compact 12-seaters to spacious 20-seater options. No matter your group size, we have the perfect vehicle for your journey.</p>
    </li>
    <li>
        <strong>Comfort and Convenience:</strong>
        <p>Our Tempo Travellers are equipped with comfortable seating, air conditioning, and ample luggage space. We ensure a pleasant travel experience, whether you’re going for a short trip or a long-distance journey.</p>
    </li>
    <li>
        <strong>Experienced and Professional Drivers:</strong>
        <p>Safety is our top priority. Our drivers are well-trained and experienced in navigating local and outstation routes. They prioritize your comfort and safety, making your journey stress-free.</p>
    </li>
    <li>
        <strong>Transparent Pricing:</strong>
        <p>At Shiv Travels66, we believe in honest and transparent pricing. Our Tempo Traveller rates are competitive, with no hidden charges. You’ll receive a detailed quote based on your itinerary, so you can budget accordingly.</p>
    </li>
    <li>
        <strong>Flexible Booking Options:</strong>
        <p>We offer convenient booking options, whether you need a vehicle for a day, a week, or a longer period. Our flexible rental plans cater to your specific travel requirements, ensuring you get the service you need.</p>
    </li>
    <li>
        <strong>Customer-Centric Approach:</strong>
        <p>Our dedicated customer support team is available to assist you at every step, from booking to the end of your journey. We value your feedback and strive to enhance your travel experience with us.</p>
    </li>
    <li>
        <strong>Timely Service:</strong>
        <p>We understand the importance of time in travel. Our commitment to punctuality ensures that our vehicles arrive on schedule, so you can reach your destination without any delays.</p>
    </li>
    <li>
        <strong>Customized Packages:</strong>
        <p>Whether you’re planning a family trip, corporate event, or religious pilgrimage, we can create customized travel packages that suit your needs and preferences. Let us know your requirements, and we’ll tailor our services accordingly.</p>
    </li>
    <li>
        <strong>Positive Customer Reviews:</strong>
        <p>We take pride in the positive feedback from our satisfied customers. Our commitment to quality service has earned us a loyal clientele, and we strive to maintain our high standards with every booking.</p>
    </li>
    <li>
        <strong>Safety Measures:</strong>
        <p>In light of current health concerns, we prioritize your safety. Our vehicles are regularly sanitized, and we adhere to all safety protocols to ensure a secure travel experience.</p>
    </li>
</ol>

    </div>
      
    <div className="faq-container">
    <h2>FAQs for Tempo Traveller on Rent in Pune? </h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 - Tempo Traveller On Rent in Pune</h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
