import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function PunetoGoa() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "We had an amazing experience traveling from Pune to Goa with Shiv Travels66! The Force Urbania was spacious and comfortable, perfect for our family of six. Our driver was professional and friendly, making several stops along the way to ensure we had a pleasant journey. The kids loved the entertainment system, and we felt safe and relaxed throughout the trip. Highly recommend Shiv Travels66 for anyone looking to travel to Goa!",
    name: "Mrs. Anita S."
  },
  {
    quote:"Our company decided to organize a corporate retreat in Goa, and we chose Shiv Travels66 for our transportation needs. From the initial booking to the drop-off, everything was seamless. The Urbania was immaculate, and our driver was punctual and knowledgeable about the best routes. The trip was not only efficient but also enjoyable, allowing us to bond as a team. I will definitely use Shiv Travels66 for our future travel needs!" 
,
    name: " Mr. Rajesh M."
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            "question": "What types of vehicles do you offer for Pune to Goa trips?",
            "answer": "We offer a range of vehicles, including the Force Urbania, which is spacious and comfortable for group travel. We also have smaller vehicles for more intimate trips."
        },
        {
            "question": "How can I book a trip from Pune to Goa with Shiv Travels66?",
            "answer": "You can easily book your trip online through our website or by calling us at +91 9764768866. Our team will assist you in selecting the right package and vehicle for your needs."
        },
        {
            "question": "What is included in the Pune to Goa tour package?",
            "answer": "Our tour packages typically include transportation, driver services, and optional accommodation and sightseeing arrangements. Custom packages can also be tailored to your preferences."
        },
        {
            "question": "How long does the journey from Pune to Goa take?",
            "answer": "The journey usually takes around 8 to 10 hours, depending on traffic and road conditions. We aim to make your trip comfortable with planned breaks along the way."
        },
        {
            "question": "Are your vehicles equipped with amenities?",
            "answer": "Yes, our Urbania vehicles are equipped with modern amenities such as air conditioning, comfortable seating, entertainment systems, and charging points for a pleasant journey."
        },
        {
            "question": "What are your cancellation and refund policies?",
            "answer": "We have a flexible cancellation policy. If you need to cancel your trip, please contact us at least 24 hours in advance for a full refund, depending on the specific terms of your booking."
        },
        {
            "question": "Can I hire a driver for my trip?",
            "answer": "All our rentals come with experienced drivers who are familiar with the routes and will ensure a safe and smooth journey. You do not need to worry about driving."
        },
        {
            "question": "Do you offer one-way trips to Goa?",
            "answer": "Yes, we offer both one-way and round-trip options for Pune to Goa travel. You can choose the option that best suits your travel plans."
        },
        {
            "question": "Are there any additional charges for the trip?",
            "answer": "Our pricing is transparent, and any additional charges (like tolls or parking) will be communicated upfront. There are no hidden fees, and we strive to provide clear and comprehensive pricing."
        },
        {
            "question": "What should I do if I have special requests for my trip?",
            "answer": "If you have specific needs or requests, such as child seats, extra stops, or specific travel times, please inform us during the booking process. We will do our best to accommodate your requirements."
        }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com/Pune-to-Goa-Urbania-On-Rent",
      "logo": "https://www.shivtravels66.com/img/Pune to Goa.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  
  
   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];

   
  return (
    <>

<Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Pune to Goa| Pune to Shirdi Innova Crysta Cab|Innova Crysta Cab
                 |Online Cab Booking Pune| Innova On Rent in Pune</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/img/Pune to Goa.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/Pune-to-Goa-Urbania-On-Rent" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>


     
      {/* <!-- page-title --> */}
      <section class="page-title centred background1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Pune to Goa Urbania</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page ">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Pune to Goa.jpg" alt="Pune to goa urbania" />
              </div>

             <div>
             <h1>Pune to Goa Urbania On Rent with Shiv Travels66</h1>

<p>Look no further! Shiv Travels66 offers Urbania rentals that provide a luxurious and comfortable travel experience for your journey. Whether you're traveling with family, friends, or colleagues, our Urbania buses are the perfect choice for a memorable trip.</p>

<h2>Pune to Goa Urbania on Rent Contact Number</h2>
<p>For all inquiries about Pune to Goa Urbania on rent, reach out to Shiv Travels66 at <strong>+91 9764768866</strong>. Our dedicated team is ready to assist you with your booking and provide all the information you need for a seamless travel experience.</p>

<h2>Find Force Urbania on Rent Near Me</h2>
<p>Searching for a Force Urbania on rent near you? Shiv Travels66 offers convenient Urbania rentals across Pune, ensuring you have easy access to our services. Whether you're planning a family trip or a corporate outing, we’ve got you covered.</p>

<h2>Force Urbania 17 Seater for Rent</h2>
<p>Traveling with a group? Our Force Urbania 17-seater for rent is the perfect solution. With spacious seating and ample luggage capacity, it’s ideal for family vacations, school trips, or gatherings with friends. Enjoy a comfortable ride from Pune to Goa with all the modern amenities you need.</p>

<h2>Pune to Goa Tour Package (3 Days)</h2>
<p>Experience the beauty of Goa with our 3-day Pune to Goa tour package. This all-inclusive package features transportation in our Urbania, comfortable accommodations, and exciting sightseeing activities. Enjoy the stunning beaches, vibrant culture, and delicious cuisine that Goa has to offer!</p>

<h2>Pune to Goa Urbania on Rent for Trip with Shiv Travels66</h2>
<p>Choose Pune to Goa Urbania on rent for your trip with Shiv Travels66 for a luxurious travel experience. Our Urbania vehicles come equipped with modern amenities to ensure comfort and convenience throughout your journey. Sit back, relax, and enjoy the scenic route from Pune to Goa.</p>

<h2>Pune to Goa Urbania on Rent for Trip Near Me</h2>
<p>If you’re looking for Pune to Goa Urbania on rent for a trip near me, Shiv Travels66 has got you covered! Our Urbania rentals provide a comfortable and spacious way to travel to Goa, making it perfect for family vacations or group outings. With convenient pickup locations throughout Pune, booking your trip has never been easier.</p>

<h2>Pune to Goa Urbania Packages</h2>
<p>Explore our customized Pune to Goa Urbania packages designed to suit your travel needs. Whether you're planning a weekend getaway or an extended vacation, we offer flexible packages that include transportation, accommodation, and exciting activities in Goa. Enjoy the beautiful beaches, vibrant nightlife, and rich culture while traveling in style!</p>

<h2>Urbania on Rent in Pune</h2>
<p>Shiv Travels66 is your trusted provider for Urbania on rent in Pune. Our fleet of well-maintained Urbania vehicles is perfect for any occasion, whether it’s a family trip, a corporate event, or a school outing. Experience luxury and comfort on your journey to Goa with our premium rentals.</p>

<h2>Pune to Goa Urbania Bus on Rent</h2>
<p>Looking for a Pune to Goa Urbania bus on rent? Our Urbania buses are equipped with modern amenities to ensure a comfortable ride. With spacious seating and plenty of luggage space, your group can travel together without any hassle. Enjoy the scenic drive from Pune to Goa in our luxurious Urbania.</p>

<h2>Hire Urbania Bus on Rent with Shiv Travels</h2>
<p>When you hire an Urbania bus on rent with Shiv Travels66, you choose reliability and comfort. Our experienced drivers ensure a safe and enjoyable journey, allowing you to focus on making memories. We take care of all your travel needs so you can relax and enjoy the ride to Goa.</p>

<h2>Pune to Goa Urbania on Rent</h2>
<p>Experience the best of both worlds with our Pune to Goa Urbania on rent service. Enjoy a luxurious travel experience while exploring the stunning landscapes and attractions along the route. Our Urbania vehicles come equipped with everything you need for a pleasant journey.</p>

<h2>Force Urbania 10 Seater on Rent in Pune</h2>
<p>If you're traveling with a smaller group, our Force Urbania 10-seater on rent in Pune is the perfect choice. This compact yet spacious vehicle offers comfort and style for intimate family trips or getaways with friends. Enjoy the journey to Goa without compromising on comfort.</p>

             </div>


          

              <div>
      <h1>Pune to Goa Urbania with Shiv Travels66 Contact Number: </h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Pune to Goa Urbania on Rent Contact Number.</p>
<p>- Force Urbania on Rent Near me.</p>
<p>- Force Urbania 17 Seater for Rent.</p>
<p>- Pune to Goa Tour package 3 day.</p>
<p>- Pune to Goa Urbania on Rent for trip.</p>
<p>- Pune to Goa urbania on rent for trip near me.</p>

          </td>
          <td style={{ borderWidth: "0px" }}>
         
          <p>- Pune to Goa Urbania Packages.</p>
<p>- Urbania On Rent in Pune.</p>
<p>- Pune to Goa urbania bus on rent.</p>
<p>- Hire Urbania bus on Rent with Shiv Travels.</p>
<p>- Pune to Goa urbania on rent.</p>
<p>- Force Urbania 10 seater on rent in Pune.</p>


          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h1>Why Choose Us for Pune to Goa Urbaia with Shiv Travels66?</h1>
   
<ul>
    <li><strong>Comfort and Space:</strong> Our Force Urbania vehicles are designed for maximum comfort, providing ample space for passengers and luggage, making your journey enjoyable.</li>
    <li><strong>Affordable Pricing:</strong> We offer competitive rates without compromising on quality. Our transparent pricing ensures no hidden fees, giving you the best value for your money.</li>
    <li><strong>Experienced Drivers:</strong> Our professional drivers are familiar with the best routes and traffic patterns, ensuring a smooth and timely journey from Pune to Mumbai.</li>
    <li><strong>Modern Amenities:</strong> Enjoy a range of modern amenities, including air conditioning, entertainment systems, and charging ports, enhancing your travel experience.</li>
    <li><strong>Flexible Booking Options:</strong> We offer convenient booking options tailored to your needs, whether it’s a one-way trip or a round trip, with flexible scheduling.</li>
    <li><strong>Excellent Customer Service:</strong> Our dedicated team is available to assist you at every step, from booking to drop-off, ensuring your trip goes smoothly.</li>
    <li><strong>Customized Travel Packages:</strong> We offer customizable tour packages that can include sightseeing and accommodation, allowing you to experience the best of Mumbai.</li>
</ul>


    </div>
      
    <div className="faq-container">
    <h2>FAQs for Shiv Travels66 regarding Pune to Mumbai with Urbania services ?</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 regarding the Pune to Mumbai urbania service:</h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
            {/* ========= */}
          </div>

          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
