import React from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';

const ForceUrbania = () => {
    const styles = {
        container: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f0f0f0',
          padding: '50px 20px',
          borderRadius: '10px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          margin: '20px',
        },
        heading: {
          fontSize: '2.5em',
          marginBottom: '20px',
          fontFamily: '"Georgia", serif',
          color: '#333',
          textAlign: 'center',
        },
        subheading: {
          fontSize: '1.5em',
          marginBottom: '20px',
          fontFamily: '"Arial", sans-serif',
          color: '#666',
          textAlign: 'center',
        },
        text: {
          fontSize: '1.2em',
          lineHeight: '1.6',
          color: '#555',
          maxWidth: '800px',
          textAlign: 'center',
          margin: '0 20px',
        },
        button: {
          marginTop: '30px',
          padding: '10px 20px',
          fontSize: '1.2em',
          color: '#fff',
          backgroundColor: '#007BFF',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
        },
        buttonHover: {
          backgroundColor: '#0056b3',
        },
        imageContainer: {
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginTop: '20px',
        },
        image: {
        //   width: '200px',
        //   height: 'auto',
          margin: '10px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
      };
    
      const handleMouseEnter = (e) => {
        e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
      };
    
      const handleMouseLeave = (e) => {
        e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
      };

      const styles1 = {
        container: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          padding: '50px 20px',
          backgroundColor: '#f9f9f9',
          borderRadius: '10px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          margin: '20px',
          flexWrap: 'wrap', // Allow wrapping for smaller screens
        },
        content: {
          flex: 1,
          padding: '20px',
          maxWidth: '600px',
          flexBasis: '45%', 
        },
        heading: {
          fontSize: '2.5em',
          marginBottom: '20px',
          fontFamily: '"Georgia", serif',
          color: '#333',
        },
        text: {
          fontSize: '1.2em',
          lineHeight: '1.6',
          color: '#555',
        },
        image: {
          width: '100%', // Make image responsive
          maxWidth: '400px',
          height: 'auto',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          marginLeft: '20px',
        },
        // Responsive Styles
        '@media (max-width: 768px)': {
          container: {
            flexDirection: 'column',
            alignItems: 'center',
          },
          content: {
            flexBasis: '100%', // Full width on smaller screens
            textAlign: 'center', // Center text for smaller screens
          },
          image: {
            marginLeft: '0', // Reset margin for smaller screens
            marginTop: '20px', // Add top margin for spacing
          },
        },
      };


      const schemaData = {
        "@context": "https://schema.org",
        "@type": "TravelAgency",
        "name": "Shiv Travels66",
        "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
        "url": "https://www.shivtravels66.com",
        "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
            "addressLocality": "Kirtane Baug Hadpasar",
            "addressRegion": "Maharashtra",
            "postalCode": "411036",
            "addressCountry": "India"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91 9764768866",
            "contactType": "Customer Service",
            "availableLanguage": "English"
        },
        "email": "booking@shivtravels66.com",
        telephone:"+91 9764768866",
        image: "https://www.shivtravels66.com/OG Image-01.jpg",
        starRating: {
          "@type": "Rating",
          bestRating: "4.8",
          ratingValue: "3",
          worstRating: "1",
        },
        "keywords": [
          "Urbania On Rent in Pune",
          "Pune to Mahabaleshwar Urbania on Rent",
          "Pune to Shirdi Urbania on Rent",
          "Urbania Hire for Outstation Pune",
          "Urbania Hire for Corporate Events in Pune"
      ]
    
    };
    
    const images = [
      'real/customer/shivtravel66-customer1.jpg',
      'real/customer/shivtravels66-customer6.jpg',
      'real/customer/shivtravels66-customer.jpg',
      'real/customer/shivtravels66-customer2.jpg',
    

    
    
    ];

    const cars = [
      { img: "real/urbania-seat1.jpg" },
      {img: "real/urbania-seat2.jpg" },
      { img: "real/urbania-seat3.jpg"},
      {  img: "real/gallery/urbania-eats1.jpg" },
      {img:'real/gallery/urbania-seats.jpg'},
    
     
    ];
    const settings1 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000,
      responsive: [
        {
            breakpoint: 1024, // Tablet
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 400, // Mobile
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
  
    };

    const amenities = [
      "Newspaper",
      "Bottle of mineral water",
      "First aid kit",
      "Tissue paper",
      "Diary",
      "GPS locator with panic button",
      "Driver in uniform",
      "Luggage storage area"
    ];
  
  return (
    <>
      <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
     {/* <!-- page-title --> */}
     <section class="page-title centred fleet-section1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Force Urbania</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}
    <div style={styles.container}>
    <h1 style={styles.heading}><span className='colorblue'>Force Urbania</span></h1>
    <h2 style={styles.subheading}>Empowering Urban Adventures With shiv Travels66</h2>
    <p style={styles.text}>
      "Join us on a journey through the urban landscape. Force Urbania is dedicated to exploring the vibrant cities, cultures, and communities that make our world unique. Whether you seek adventure, culture, or connection, we have the resources to make your urban experience unforgettable."
    </p>
    
   
  </div>


<div style={styles1.container}>
      <div style={styles1.content}>
        <h1 style={styles1.heading}><span className='colorblue'>13-Seater Urbania</span></h1>
        <p style={styles1.text}>
          The 13-Seater Urbania is designed for small groups and family trips, offering a blend of comfort and efficiency. 
          Its spacious interior provides ample legroom and high-back seating, ensuring a pleasant journey for all passengers.
        </p>
        <h2 style={styles1.heading}><span className='colorblue'>Key Features:</span></h2>
        <ul style={styles1.text} className='mylist'>
          <li>Robust diesel engine for optimal performance</li>
          <li>Comfortable seating with adjustable headrests</li>
          <li>Advanced safety features including airbags and ABS</li>
          <li>Air conditioning and entertainment system for a pleasant ride</li>
          <li>Ample luggage space for travel convenience</li>
        </ul>
      </div>
      <img
        src="real/gallery/urbania5.jpg" // Replace with your image URL
        alt="13-Seater Urbania"
        style={styles1.image}
      />
    </div>

    <div style={styles1.container}>
    <img
        src="real/urbania-shivtravels.jpg" 
        alt="17-Seater Urbania"
        style={styles1.image}
      />
      <div style={styles1.content}>
        <h1 style={styles1.heading}><span className='colorblue'>17-Seater Urbania</span></h1>
        <p style={styles1.text}>
          The 17-Seater Urbania is the perfect solution for larger groups, combining spaciousness with comfort and efficiency. 
          This vehicle is designed for smooth rides, ensuring that every passenger enjoys ample legroom and high-quality seating.
        </p>
        <h2 style={styles1.heading}><span className='colorblue'>Key Features:</span></h2>
        <ul style={styles1.text}>
          <li>Powerful diesel engine for enhanced performance</li>
          <li>Spacious seating layout for maximum comfort</li>
          <li>Advanced safety features including multiple airbags and ABS</li>
          <li>Air conditioning and an entertainment system for a delightful journey</li>
          <li>Generous luggage space to accommodate all your needs</li>
        </ul>
      </div>
     
    </div>


    <h1 className="text-center pb-3 pt-4"><span className="colorblue">Happy Customer</span></h1>
        <div className="gallery">
         
            {images.map((img, index) => (
                <div className="gallery-item" key={index}>
                    <img src={img} alt={`Gallery item ${index + 1}`} className="galleryimg" />
                </div>
            ))}
        </div> 

        <section className="pricing-section pt-3 pb-3 container">
      <h1 className="text-center">Explore</h1>
<div className="border-line"></div>
    <div className=" container slider-container">
   
      <Slider {...settings1}>
        {cars.map((car, index) => (
          <div key={index} className="slide text-center">
            <div className="innerimg m-2">
              <div className="cardbox">
            <div className="service-imgbox">
                <img src={car.img} alt={car.name} className="m-auto"/>
            </div>
           
            <h5 className="pt-2 pb-2">{car.name}</h5>
            <p>{car.para}</p>
         
            <a href="https://api.whatsapp.com/send/?phone=919764768866&text= ."  target="_blank" className="read-more1">
                                                        Book Now 
                                                        </a>
          </div>
          </div>
          </div>
        ))}
      </Slider>
    </div>
   </section>
   

   <div className="container mt-5">
      <div className="row align-items-center container-fluid">
        {/* Cards Section */}
        <div className="col-md-6">
          <div className="card2 mb-4 shadow">
            <div className="card2-body p-3">
              <h5 className="card2-title">Force Urbania Rental in Pune</h5>
              <p className="card2-text">Looking for a Force Urbania on rent?</p>
              <h6 className="card2-subtitle mb-2 text-muted">Pricing:</h6>
              <p className="card2-text">
                <strong>Urbania AC:</strong> ₹36 per km
              </p>
              
              <p className="card2-text text-danger font-weight-bold">
                Limited Time Offer! Book your vehicle right now.
              </p>
              <a href="https://api.whatsapp.com/send/?phone=919764768866&text= ."  target="_blank" className="read-more1">
                                                        Book Now 
                                                        </a>
            </div>

            <div className="container mt-5">
      <h2 className="text-center mb-4">Shiv Travels Urbania Vehicle Facilities & Amenities</h2>
      <ul className="list-group">
        {amenities.map((amenity, index) => (
          <li key={index} className="list-group-item">
            {amenity}
          </li>
        ))}
      </ul>
      <div className="mt-4 text-center">
        <h5 className='colorblue'>FORCE URBANIA ON RENT IN PUNE</h5>
        <p>Shiv Travels provides the car rental service.</p>
      </div>
    </div>
          </div>
        </div>

        {/* Image Section */}
        <div className="col-md-6">
          <div className="image-container">
            <img src="real/shivtravels-aboutimg.jpg" alt="Force Urbania" className="img-fluid rounded" />
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ForceUrbania;
