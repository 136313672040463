import React, { useState } from 'react';

const TourTab = ({ tours }) => (
  <div className="row clearfix">
    {tours.map((tour, index) => (
      <div className="col-lg-3 col-md-6 col-sm-12 tours-block" key={index}>
        <div className="tours-block-one">
          <div className="inner-box">
            <div className="image-box">
              <figure className="image">
                <img src={tour.img} alt={tour.title} />
              </figure>
              <span className="rating">
                <img src="assets/images/icons/icon-1.png" alt="rating" /> {tour.rating}
              </span>
            </div>
            <div className="lower-content">
              <h6>{tour.location}</h6>
              <h4>
                <a href="tour-details.html">{tour.title}</a>
              </h4>
              <h5>
                <span>From - </span>${tour.price}
              </h5>
              <span className="day-time">
                <i className="icon-1"></i> {tour.duration}
              </span>
              <div className="link">
                <a href="tour-details.html">
                  Explore more <i className="fas fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

const ToursSection = () => {
  const [activeTab, setActiveTab] = useState('#tab-1');

  const toursData = {
    '#tab-1': [
      { location: 'Japan', title: 'Beauty of Istanbul Turkey', price: 199, rating: 4.8, duration: '6 Nights / 7 Days', img: 'images/resource/tour-1.jpg' },
      { location: 'USA', title: 'Wildness of California', price: 250, rating: 5.0, duration: '4 Nights / 5 Days', img: 'images/resource/tour-2.jpg' },
      { location: 'Maldives', title: 'Ocean on the Maldives', price: 150, rating: 4.0, duration: '5 Nights / 6 Days', img: 'images/resource/tour-3.jpg' },
      { location: "Japan",  title: "Beauty of Istanbul Turkey", price: 199,  rating: 4.8, duration: "6 Nights / 7 Days",  img: "images/resource/tour-1.jpg"},
      { location: "USA", title: "Wildness of California",price: 250, rating: 5.0, duration: "4 Nights / 5 Days", img: "images/resource/tour-2.jpg"},
    ],
    '#tab-2':[
      { location: 'Maldives', title: 'Ocean on the Maldives', price: 150, rating: 4.0, duration: '5 Nights / 6 Days', img: 'images/resource/tour-3.jpg' },
      { location: "Japan",  title: "Beauty of Istanbul Turkey", price: 199,  rating: 4.8, duration: "6 Nights / 7 Days",  img: "images/resource/tour-1.jpg"},
      {  location: "USA", title: "Wildness of California",price: 250, rating: 5.0, duration: "4 Nights / 5 Days", img: "images/resource/tour-2.jpg"},
      {location: "Maldives", title: "Ocean on the Maldives",   price: 150, rating: 4.0,  duration: "5 Nights / 6 Days", img: "images/resource/tour-3.jpg" },
      {  location: "Africa",title: "The Hills and Mountains",   price: 299,rating: 4.5,  duration: "6 Nights / 7 Days", img: "images/resource/tour-4.jpg", }, 
    
    
    ],
      

  };

  return (
    <section className="tours-section pt_100 pb_70">
      <div className="pattern-layer" style={{ backgroundImage: 'url(assets/images/shape/shape-12.png)' }}></div>
      <div className="auto-container">
        <div className="tabs-box">
          <div className="upper-box mb_60">
            <div className="sec-title">
              <span className="sub-title">Tour Packages</span>
              <h2>Featured Tours</h2>
            </div>
            <div className="tab-btn-box p_relative">
              <ul className="tab-btns tab-buttons clearfix">
                <li className={`tab-btn ${activeTab === '#tab-1' ? 'active-btn' : ''}`} onClick={() => setActiveTab('#tab-1')}>All</li>
                <li className={`tab-btn ${activeTab === '#tab-2' ? 'active-btn' : ''}`} onClick={() => setActiveTab('#tab-2')}>USA</li>
                <li className={`tab-btn ${activeTab === '#tab-3' ? 'active-btn' : ''}`} onClick={() => setActiveTab('#tab-3')}>England</li>
                <li className={`tab-btn ${activeTab === '#tab-4' ? 'active-btn' : ''}`} onClick={() => setActiveTab('#tab-4')}>Egypt</li>
                <li className={`tab-btn ${activeTab === '#tab-5' ? 'active-btn' : ''}`} onClick={() => setActiveTab('#tab-5')}>India</li>
                <li className={`tab-btn ${activeTab === '#tab-6' ? 'active-btn' : ''}`} onClick={() => setActiveTab('#tab-6')}>Europe</li>
                <li className={`tab-btn ${activeTab === '#tab-7' ? 'active-btn' : ''}`} onClick={() => setActiveTab('#tab-7')}>America</li>
              </ul>
            </div>
          </div>
          <div className="tabs-content">
            <div className={`tab ${activeTab === '#tab-1' ? 'active-tab' : ''}`} id="tab-1">
              <TourTab tours={toursData[activeTab]} />
            </div>
          </div>
          <div className="tabs-content">
            <div className={`tab ${activeTab === '#tab-2' ? 'active-tab' : ''}`} id="tab-2">
              <TourTab tours={toursData[activeTab]} />
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default ToursSection;
