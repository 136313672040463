import React, { useEffect } from 'react';

const Carousel = () => {
  useEffect(() => {
    if (window.bootstrap && window.bootstrap.Carousel) {
      const carouselElement = document.getElementById('carouselExample');
      if (carouselElement) {
        new window.bootstrap.Carousel(carouselElement, {
          interval: 1000,
          ride: 'carousel',
        });
      }
    } else {
      console.error('Bootstrap JavaScript is not loaded. Make sure it is linked correctly.');
    }
  }, []);

  return (
    <div id="carouselExample" className="carousel slide" data-bs-ride="carousel" data-bs-interval="1000">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src="img/Slider/Slider1.jpg" className="d-block w-100" alt="Slide 1" />
        </div>
        <div className="carousel-item">
          <img src="img/Slider/Slider2.jpg" className="d-block w-100" alt="Slide 2" />
        </div>
        <div className="carousel-item">
          <img src="img/Slider/Slider3.jpg" className="d-block w-100" alt="Slide 3" />
        </div>
        <div className="carousel-item">
          <img src="img/Slider/Slider4.jpg" className="d-block w-100" alt="Slide 4" />
        </div>
        <div className="carousel-item">
          <img src="img/Slider/Slider5.jpg" className="d-block w-100" alt="Slide 5" />
        </div>
        <div className="carousel-item">
          <img src="img/Slider/Slider6.jpg" className="d-block w-100" alt="Slide 6" />
        </div>
        <div className="carousel-item">
          <img src="img/Slider/Slider7.jpg" className="d-block w-100" alt="Slide 7" />
        </div>
        
        {/* Additional slides */}
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>

    
  );
};

 export default Carousel;


// import React, { useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';


// const Carousel = () => {
//   useEffect(() => {
//     const bootstrap = require('bootstrap/dist/js/bootstrap.bundle.min');
//     const carouselElement = document.querySelector('#carouselExampleControls');
//     if (carouselElement) {
//       new bootstrap.Carousel(carouselElement, {
//         ride: 'carousel',
//         interval: 3000,  
//       });
//     }
//   }, []);

//   return (
//     <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
//       <div className="carousel-inner">
//         <div className="carousel-item active">
//           <img src="img/Slider/Slider1.jpg" className="d-block w-100" alt="Slide 1" />
//         </div>
//         <div className="carousel-item">
//           <img src="img/Slider/Slider2.jpg" className="d-block w-100" alt="Slide 2" />
//         </div>
//         <div className="carousel-item">
//           <img src="img/Slider/Slider3.jpg" className="d-block w-100" alt="Slide 3" />
//         </div>
//         <div className="carousel-item">
//           <img src="img/Slider/Slider4.jpg" className="d-block w-100" alt="Slide 4" />
//         </div>
//         <div className="carousel-item">
//           <img src="img/Slider/Slider5.jpg" className="d-block w-100" alt="Slide 5" />
//         </div>
//         <div className="carousel-item">
//           <img src="img/Slider/Slider6.jpg" className="d-block w-100" alt="Slide 6" />
//         </div>
//         <div className="carousel-item">
//           <img src="img/Slider/Slider7.jpg" className="d-block w-100" alt="Slide 7" />
//         </div>
//       </div>
//       <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
//         <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//         <span className="visually-hidden">Previous</span>
//       </button>
//       <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
//         <span className="carousel-control-next-icon" aria-hidden="true"></span>
//         <span className="visually-hidden">Next</span>
//       </button>
//     </div>
//   );
// };

// export default Carousel;




