import { Helmet } from "react-helmet";


export default function Services(){
    const newsItems = [
      
        {
            category: "Covid Travel",
            date: "19 Mar, 2023",
            title: "Corporate Travel Solutions",
            image: 'services/coprate.png',
            link: "Urbania-Hire-for-Corporate-Events-in-Pune",
            para:'Corporate travel solutions provided by cab services typically include a range of features and services tailored to meet the needs of businesses and their employees.'
        },
        {
            category: "Europe",
            date: "18 Mar, 2023",
            title: "24/7 Customer Service",
            image: 'services/Customer Support.png',
            link: "Tempo-Traveller-Hire-for-Wedding-in-Pune",
            para:'Assistance with booking cabs at any hour, whether online, through an app, or via phone.Support for changes to your itinerary, such as rescheduling pickup times etc.'
        },
        {
            category: "Europe",
            date: "18 Mar, 2023",
            title: "Experienced Drivers",
            image: 'services/experinDriver (1).png',
            link: "Urbania-on-rent-in-puneml",
            para:'Our professional drivers undergo rigorous training to ensure your safety and comfort, with in-depth knowledge of routes and destinations, navigating any road with ease.'
        },
        {
            category: "Europe",
            date: "18 Mar, 2023",
            title: "GPS Tracking for Real-Time Updates",
            image: 'services/gps tracking.png',
            link: "Online-Cab-Booking-Pune",
            para:'Our vehicles are equipped with GPS tracking, providing you with real-time updates on your journey. Stay informed about your travel progress and reach your destination with confidence.'
        },
        {
            category: "Europe",
            date: "18 Mar, 2023",
            title: "Clean and Well-Maintained Vehicles",
            image: 'services/cleanvehcle.png',
            link: "Innova-Crysta-on-Rent-in-Pune",
            para:'Cleanliness and vehicle maintenance are paramount to us. Our fleet is regularly serviced and sanitized to provide you with a comfortable and hygienic travel experience.'
        },
      
        {
            category: "Europe",
            date: "18 Mar, 2023",
            title: "Customized Travel Packages",
            image: 'services/travelpackage.png',
            link: "Pune-Tempo-Traveller-Hire-for-Outstation",
            para:'Tailor your travel experience to your preferences with our customized packages, whether its a solo adventure, family vacation, or romantic getaway, exceeding your expectations.'

        },
    ];


    const schemaData = {
        "@context": "https://schema.org",
        "@type": "TravelAgency",
        "name": "Shiv Travels66",
        "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
        "url": "https://www.shivtravels66.com",
        "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
            "addressLocality": "Kirtane Baug Hadpasar",
            "addressRegion": "Maharashtra",
            "postalCode": "411036",
            "addressCountry": "India"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91 9764768866",
            "contactType": "Customer Service",
            "availableLanguage": "English"
        },
        "email": "booking@shivtravels66.com",
        telephone:"+91 9764768866",
        image: "https://www.shivtravels66.com/OG Image-01.jpg",
        starRating: {
          "@type": "Rating",
          bestRating: "4.8",
          ratingValue: "3",
          worstRating: "1",
        },
        "keywords": [
          "Urbania On Rent in Pune",
          "Pune to Mahabaleshwar Urbania on Rent",
          "Pune to Shirdi Urbania on Rent",
          "Urbania Hire for Outstation Pune",
          "Urbania Hire for Corporate Events in Pune"
      ]
    
    };
    
    

    
    return(
        <>
         <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>

         {/* <!-- page-title --> */}
      <section class="page-title centred background2">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(background/2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels</span>
            <h1>Services</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}
        <section className="news-section pt_100 pb_65">
            <div className="auto-container">
            
                <div className="row clearfix">
                    {newsItems.map((item, index) => (
                        <div className="col-lg-4 col-md-6 col-sm-12 news-block" key={index}>
                            <div className="news-block-one wow fadeInUp animated" data-wow-delay={`${index * 300}ms`} data-wow-duration="1500ms">
                                <div className="inner-box myinnerbox">
                                    <figure className="image-box">
                                        <a href={item.link}><img src={item.image} alt={item.title} /></a>
                                    </figure>
                                    <div className="lower-content service-background">
                                        
                                        <h3><a href={item.link}>{item.title}</a></h3>
                                        <p>{item.para}</p>
                                        
                                        <div className="read-btn">
                                        <a href={item.link}className="">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
        </>
    )
}