import "./css/animate.css";
import "./css/bootstrap.css";
import "./css/color.css";
import "./css/elpath.css";
import './css/flaticon.css';
import './css/font-awesome-all.css';
import './css/jquery.fancybox.min.css';
import './css/nice-select.css';
import './css/owl.css';
import './css/responsive.css';
import './css/style.css';
import './css/switcher-style.css';
import './css/module-css/banner.css'
import './css/module-css/about.css';
import './css/module-css/blog-details.css';
import './Navbar.css';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import ForceUrbania from './ForceUrbania';
import InnovaCrysta from './InnovaCrysta';



export default function Header(){
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleNavItemClick = () => {
    setIsOpen(false); // Close the navbar when a nav item is clicked
  };
    return(
        <>
          {/* <!-- main header --> */}
        <header class="main-header header-style-two">
            {/* ======================= */}

            <div className="bg-dark header1">
        <div class="container row text-center pt-2 pb-2">
          <div className="col-12 col-lg-3 ">
            <a href="tel:+91 7058638153" className="text-white fw-bold">
              <i class="bi bi-telephone-fill me-2"></i>
              <a href="tel:+91 9764768866" className="text-white">+91 9764768866</a>
            </a>
          </div>
          <div className="col-12 col-lg-4 ">
            <a
              href="  mailto:booking@shivtravels66.com"
              className="text-white fw-bold"
            >
              <i class="bi bi-envelope me-2"></i>
              <a href="mailto:booking@shivtravels66.com" className="text-white">
              booking@shivtravels66.com
              </a>
            </a>
          </div>
        </div>
      </div> 

            {/* <!-- header-lower --> */}
            <div class="header-lower">
          

<nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container">
        <div className="logo">
          <Link className="navbar-brand" to="/">
            <img src="./img/Logo sizes-01.jpg" alt="logo" />
          </Link>
        </div>
        
        <button
          className="navbar-toggler navbar-button"
          type="button"
          onClick={handleToggle}
          aria-controls="navbarNav"
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon line"></span>
        </button>

        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" to="/" onClick={handleNavItemClick}>Home</Link>
            </li>
            
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="/about" onClick={handleNavItemClick}>About</Link></li>
                <li><Link className="dropdown-item" to="/policy" onClick={handleNavItemClick}>Privacy & Policy</Link></li>
                <li><Link className="dropdown-item" to="/terms" onClick={handleNavItemClick}>Terms & Condition</Link></li>
              </ul>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/fleets" onClick={handleNavItemClick}>Fleets</Link>
            </li>
           
            <li className="nav-item">
              <Link className="nav-link" to="/forceurbania" onClick={handleNavItemClick}>Force Urbania</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Innova-Crysta" onClick={handleNavItemClick}>Innova Crysta</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/packages" onClick={handleNavItemClick}>Packages</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services" onClick={handleNavItemClick}>Services</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/gallery" onClick={handleNavItemClick}>Gallery</Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/enquiry" onClick={handleNavItemClick}>Enquiry</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact" onClick={handleNavItemClick}>Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
            </div>
        </header>
        {/* <!-- main-header end --> */}

        </>
    )
}