import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function MinibusonRentInPune() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "We recently hired a 20-seater mini bus from Shiv Travels66 for our family reunion trip to Mahabaleshwar, and we couldn’t be happier with the experience! The bus was clean, comfortable, and equipped with all the amenities we needed. Our driver was friendly and knew all the best routes. It made our journey smooth and enjoyable. Highly recommend Shiv Travels66 for anyone looking for reliable transportation!",
    name: "Mr. Rahul Deshmukh"
  },
  {
    quote: "I booked a 14-seater mini bus from Shiv Travels66 for my team’s outing, and it was a fantastic experience! The booking process was easy, and the customer service was exceptional. Our driver arrived on time and was very accommodating throughout the trip. The bus was spacious and comfortable, making it perfect for our long drive. I’ll definitely use Shiv Travels66 for future group trips!",
    name: "Mrs.Priya Sharma"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            "question": "What types of mini buses are available for rent in Pune?",
            "answer": "We offer a variety of mini buses, including 12-seater, 13-seater, 18-seater, 20-seater, and luxury options. Each vehicle is equipped for comfort and convenience, making them suitable for various occasions."
        },
        {
            "question": "How can I book a mini bus with Shiv Travels66?",
            "answer": "You can book a mini bus by contacting us through our website, phone, or email. Our customer service team will guide you through the booking process and help you choose the right vehicle for your needs."
        },
        {
            "question": "What is the pricing structure for renting a mini bus?",
            "answer": "Our pricing is competitive and varies based on factors such as the type of bus, rental duration, and distance traveled. We provide transparent quotes with no hidden fees. Please contact us for a personalized quote."
        },
        {
            "question": "Are the mini buses air-conditioned?",
            "answer": "Yes, all our mini buses are air-conditioned to ensure a comfortable travel experience, especially during the hot months."
        },
        {
            "question": "Do you provide drivers with the mini buses?",
            "answer": "Absolutely! All our mini bus rentals come with experienced and professional drivers who are familiar with local routes and committed to ensuring your safety and comfort."
        },
        {
            "question": "Can I customize my itinerary for the mini bus rental?",
            "answer": "Yes, we offer customizable itineraries to suit your specific needs. Whether you're planning a city tour, a corporate event, or a family outing, we can tailor the rental to fit your schedule."
        },
        {
            "question": "Is there a minimum rental duration for the mini bus?",
            "answer": "Typically, our minimum rental duration is 4 hours, but this can vary depending on the specific requirements of your booking. Please check with us for details."
        },
        {
            "question": "What should I do if I need to change my booking?",
            "answer": "If you need to modify your booking, please contact our customer support team as soon as possible. We will do our best to accommodate your changes based on availability."
        },
        {
            "question": "Are there any additional charges I should be aware of?",
            "answer": "While we strive for transparency, potential additional charges may include fuel surcharges, tolls, or parking fees. We will inform you of any possible charges during the booking process."
        },
        {
            "question": "How far in advance should I book a mini bus?",
            "answer": "To ensure availability, we recommend booking your mini bus at least a week in advance, especially during peak travel seasons or for large group rentals. However, last-minute bookings can sometimes be accommodated, so feel free to reach out!"
        }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com/Mini-Bus-On-Rent-in-Pune",
      "logo": "https://www.shivtravels66.com/img/Mini-Bus-On-Rent-in-Pune.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.6",
        ratingValue: "3",
        worstRating: "1.5",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  
   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];

   
  return (
    <>
  <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Tempo Traveller Hire for Wedding in Pune | Mini Bus On Rent in Pune
              | Innova Crysta on Rent in Pune | Pune to Goa Urbania On Rent</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/img/Mini-Bus-On-Rent-in-Pune.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/Mini-Bus-On-Rent-in-Pune" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Tempo Traveller Hire for Wedding in Pune, Mini Bus On Rent in Pune
                |Innova Crysta on Rent in Pune ,Pune to Goa Urbania On Rent" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
     
      {/* <!-- page-title --> */}
      <section class="page-title centred car-img1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels</span>
            <h1>Mini Bus on Rent in Pune</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Mini-Bus-On-Rent-in-Pune.jpg" alt="Mini Bus Hire on rent in Pune" />
              </div>

              <div>
              <h1>Mini Bus on Rent in Pune with Shiv Travels66</h1>

<p>Are you planning a group outing, corporate event, or family function in Pune? Look no further! Shiv Travels66 offers reliable and comfortable mini bus rentals tailored to meet your transportation needs. Our fleet of well-maintained mini buses is perfect for various occasions, ensuring your journey is as enjoyable as your destination.</p>

<h2>Mini Bus Hire in Pune</h2>
<p>Our mini bus hire in Pune service is designed to accommodate groups of all sizes. Whether you’re planning a corporate event, wedding, family outing, or a weekend trip, we have the right vehicle for you. Our mini buses are spacious, comfortable, and equipped with modern amenities to make your journey enjoyable.</p>

<h2>Mini Bus Rental Pune</h2>
<p>At Shiv Travels66, we offer flexible mini bus rentals in Pune that can be customized to meet your specific needs. You can choose from different sizes and models, ensuring that you have the perfect vehicle for your group. Our rental process is simple, transparent, and affordable, making it easy for you to book your transportation.</p>

<h2>Mini Bus on Rent</h2>
<p>Renting a mini bus is an excellent solution for group travel. Our mini buses on rent come with professional drivers who are familiar with the best routes in and around Pune. This ensures a smooth and hassle-free experience, allowing you to focus on enjoying your trip.</p>

<h2>Advantages of Mini Bus Rentals:</h2>
<ul>
    <li>Spacious Seating: Plenty of room for passengers and luggage.</li>
    <li>Cost-Effective: Economical compared to hiring multiple vehicles.</li>
    <li>Group Travel Convenience: Keep your group together and enjoy the journey together.</li>
</ul>

<h2>Small Bus Rental</h2>
<p>If you’re looking for a small bus rental, our mini buses are perfect for intimate gatherings and small groups. With seating options ranging from 12 to 20 passengers, we can accommodate any small group requirement. Our small buses are ideal for family reunions, small corporate outings, or trips to local attractions.</p>

<h2>Mini Coach Bus Rental</h2>
<p>For larger groups, consider our mini coach bus rental options. These buses are designed for comfort and provide ample space for all passengers. Perfect for longer journeys or special events, our mini coaches ensure everyone arrives at their destination relaxed and happy.</p>

<h2>13-Seater Minibus Hire</h2>
<p>Our 13-seater minibus hire is ideal for mid-sized groups. Whether you’re planning a day trip or an airport transfer, this option offers the perfect balance of space and comfort. Enjoy a smooth ride with friends or family while we handle the driving.</p>

<h2>Luxury Mini Bus Rental</h2>
<p>Make your journey extra special with our luxury mini bus rental service. These high-end vehicles come equipped with premium features and comfortable seating, providing a lavish travel experience. Perfect for weddings or corporate events where first impressions matter.</p>

<h2>12-Seater Mini Bus on Rent</h2>
<p>Our 12-seater mini bus on rent is a popular choice for small groups looking to travel together. This option is perfect for outings, local tours, or transportation to events. Enjoy the comfort and convenience of traveling with your group without the hassle of multiple vehicles.</p>

<h2>20-Seater Mini Bus on Rent</h2>
<p>For larger gatherings, our 20-seater mini bus on rent is an excellent option. Ideal for events such as family gatherings, corporate events, or school trips, this bus provides ample space for everyone, ensuring a comfortable journey.</p>

<h2>Small Party Bus Rental Prices in Pune</h2>
<p>At Shiv Travels66, we offer competitive small party bus rental prices in Pune. Our pricing is transparent, with no hidden fees, ensuring you get the best value for your money. Whether you need a vehicle for a few hours or several days, we have packages that can fit your budget.</p>

<h2>Mini Bus Hire in Pune with Shiv Travels66</h2>
<p>If you're searching for a reliable mini bus hire service in Pune, look no further than Shiv Travels66. We specialize in providing a range of mini buses to cater to your transportation needs, whether it's for a corporate event, wedding, family gathering, or a sightseeing tour. Our vehicles are well-maintained and designed to ensure a comfortable ride for all passengers.</p>

<h2>Mini Bus on Rent</h2>
<p>Our mini bus on rent service offers flexibility and convenience for group travel. Choose from a variety of seating capacities to fit your group size, making it easy to transport everyone together. Enjoy the comfort of traveling in a mini bus while our professional drivers take care of the roads.</p>

<ul>
    <li>Comfortable Seating: Ample legroom and seating arrangements for all passengers.</li>
    <li>Cost-Effective: Save money by renting a single vehicle instead of multiple cars.</li>
    <li>Convenience: Keep your group together and enjoy a shared travel experience.</li>
</ul>

<h2>Mini Bus for Rent Near Me</h2>
<p>Searching for a mini bus for rent near me? With Shiv Travels66, you can quickly find the perfect mini bus for your needs. Our services cover all areas in and around Pune, ensuring that you're never too far from quality transportation.</p>

<h2>20-Seater Minibus</h2>
<p>Our 20-seater minibus is perfect for larger groups looking for comfortable and spacious travel options. Ideal for corporate outings, family events, or school trips, this mini bus ensures that everyone travels together without any hassle.</p>

<h2>12-Seater Minibus</h2>
<p>The 12-seater minibus is a popular choice for smaller groups. Whether you're planning a day trip or an airport transfer, this option provides enough room for your group to travel comfortably. Enjoy a smooth ride while making memories together.</p>

<h2>13-Seater Mini Bus</h2>
<p>For those needing a bit more space, our 13-seater mini bus is an excellent option. This size is perfect for family gatherings, outings with friends, or short trips around the city, providing ample space for everyone and their luggage.</p>

<h2>24-Seater Minibus</h2>
<p>If you're organizing a larger event, our 24-seater minibus can accommodate bigger groups without compromising comfort. This option is perfect for events such as weddings, corporate retreats, or group travel for outings to popular tourist destinations.</p>

<h2>Luxury Minibus on Rent Pune</h2>
<p>For a touch of elegance, consider our luxury minibus on rent in Pune. This option is ideal for special occasions like weddings, VIP transfers, or corporate events where comfort and style are paramount. Enjoy plush seating and modern amenities while you travel in style.</p>

<h2>18 Seater Minibus in Pune</h2>
<p>Our 18-seater minibus in Pune is a versatile option that strikes a balance between capacity and comfort. Whether you're going on a team-building retreat, a family trip, or a local sightseeing tour, this mini bus provides ample space for all your needs.</p>

              </div>

              
              <div>
      <h1>Mini Bus On Rent in Pune with Shiv Travels66 Contact Number :- </h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Mini Bus On Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Mini bus rental Pune</p>
<p>- Mini bus on rent</p>
<p>- Mini bus rentals</p>
<p>- Mini bus hire</p>
<p>- Small bus rental</p>
<p>- Mini bus rentals near me</p>
<p>- Mini coach bus rental</p>
<p>- Small party bus rental prices</p>
<p>- 13 seater minibus hire</p>
<p>- Luxury mini bus rental</p>
<p>- 12 seater mini bus on rent</p>



          </td>
          <td style={{ borderWidth: "0px" }}>
        
<p>- 20 seater mini bus on rent</p>
<p>- Small party bus rental prices in Pune</p>
<p>- Mini bus hire</p>
<p>- Mini bus on rent</p>
<p>- Mini bus for rent near me</p>
<p>- 20 seater minibus</p>
<p>- 12 seater minibus</p>
<p>- 13 seater mini bus</p>
<p>- 24 seater minibus</p>
<p>- Luxury minibus on rent Pune</p>
<p>- 18 seater minibus in Pune</p>
          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h1>Why Choose Us for Mini Bus on Rent in Pune with Shiv Travels66?</h1>
<p>When it comes to reliable and comfortable transportation in Pune, Shiv Travels66 stands out as the premier choice for mini bus rentals. Here are several reasons why we should be your go-to option:</p>

<ol>
    <li>
        <strong>Diverse Fleet of Mini Buses:</strong> We offer a wide range of mini buses to suit every group size and occasion. Whether you need a 12-seater, 13-seater, 18-seater, 20-seater, or even a luxury minibus, we have the perfect vehicle for you. Our fleet is regularly maintained to ensure safety and comfort for all passengers.
    </li>
    <li>
        <strong>Affordable Pricing:</strong> At Shiv Travels66, we believe that quality transportation shouldn’t break the bank. Our mini bus rental services are competitively priced, offering excellent value for money. We provide transparent quotes with no hidden fees, so you know exactly what to expect.
    </li>
    <li>
        <strong>Comfort and Convenience:</strong> Traveling in a mini bus means you and your group can enjoy the journey together. Our vehicles are designed for comfort, featuring spacious seating and amenities that make long journeys enjoyable. Sit back, relax, and create memories with your friends or family.
    </li>
    <li>
        <strong>Experienced and Professional Drivers:</strong> Your safety is our priority. Our drivers are not only experienced and licensed but also familiar with the best routes in and around Pune. They are trained to provide excellent customer service, ensuring a smooth and pleasant ride for everyone.
    </li>
    <li>
        <strong>Flexible Booking Options:</strong> We understand that plans can change. That’s why we offer flexible booking options for our mini bus rentals. Whether you need a bus for a few hours or an entire day, we can accommodate your schedule and requirements.
    </li>
    <li>
        <strong>Customizable Itineraries:</strong> At Shiv Travels66, we believe in providing a personalized experience. Our team can help you customize your itinerary based on your specific needs, whether it's for corporate events, weddings, family outings, or sightseeing tours.
    </li>
    <li>
        <strong>24/7 Customer Support:</strong> Our commitment to you doesn't end with the booking. We provide 24/7 customer support to address any inquiries or issues you may have before, during, or after your journey. We’re always here to assist you.
    </li>
    <li>
        <strong>Positive Customer Reviews:</strong> We take pride in our reputation for excellent service. Our satisfied customers often leave positive reviews, highlighting their enjoyable experiences with our mini bus rentals. Join the many who have chosen Shiv Travels66 for their transportation needs.
    </li>
    <li>
        <strong>Convenient Locations:</strong> With our services available throughout Pune and surrounding areas, finding a mini bus on rent has never been easier. We are committed to providing convenient transportation solutions wherever you are.
    </li>
</ol>

    </div>
      
    <div className="faq-container">
    <h2> frequently asked questions (FAQs) for Shiv Travels66 regarding Mini Bus on Rent in Pune?</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 regarding Mini Bus on Rent in Pune- </h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>

          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>
        </div>
      </section>
    </>
  );
}
