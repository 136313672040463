import { Link } from 'react-router-dom';

export default function Footer(){
    return(
        <>
           <footer class="main-footer ">
            <div class="pattern-layer" style={{"background-image": "url(images/shape/shape-5.png)"}}></div>
            <div class="widget-section p_relative pt_30 ">
                <div class="container">
                    <div class="row clearfix container">
                        <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget logo-widget">
                                {/* <figure class="footer-logo"><a href="index.html"><img src="img/Logo sizes-01.jpg" alt=""/></a></figure> */}
                                <div className="logo mb-3">
                       <Link className="navbar-brand" to="/">
                        <img src="./img/Logo sizes-01.jpg" alt="logo" />
                     </Link>
                       </div>
                                <div class="text-box">
                                     {/* <p>At Shiv Travels66, we believe in making your travel experiences smooth, affordable, and memorable.</p> */}
                                    <ul class="info-list clearfix">
                                        <li className="email-text"><a href="mailto:booking@shivtravels66.com"><i class="bi bi-envelope me-3"></i>booking@shivtravels66.com</a></li>
                                        <li className="email-text"><a href="tel:+91 9764768866"><i class="bi bi-telephone-inbound-fill me-3"></i>+91 9764768866</a></li>
                               
                                            <p className="email-text"> Shiv Travels 66,<br/>Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036. </p>
                                      
                                    </ul>

                                    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2989.9702679499032!2d73.9347374!3d18.5276986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c174a3bebeed%3A0xd70f06012dc8b647!2sHermosa%20Casa%20By%20Sukhwani!5e1!3m2!1sen!2sin!4v1729850239456!5m2!1sen!2sin"
        width="100%"
        height="150"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      ></iframe>
    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget links-widget">
                                <div class="widget-title">
                                    <h3>Services</h3>
                                </div>
                                <div class="widget-content">
                                <ul className="links-list clearfix">
                                   <li><Link to="/Urbania-on-rent-in-pune"><i class="bi bi-car-front-fill"></i> Urbania On Rent in Pune</Link></li>
                                   <li><Link to="/Pune-to-Mahabaleshwar-Urbania-on-Rent"><i class="bi bi-car-front-fill"></i> Pune to Mahabaleshwar Urbania on Rent</Link></li>
                                   <li><Link to="/Pune-to-Shirdi-Urbania-on-Rent"><i class="bi bi-car-front-fill"></i> Pune to Shirdi Urbania on Rent</Link></li>
                                   <li><Link to="/Urbania-Hire-for-Outstation-Pune"><i class="bi bi-car-front-fill"></i> Urbania Hire for Outstation Pune</Link></li>
                                  <li><Link to="/Urbania-Hire-for-Corporate-Events-in-Pune"><i class="bi bi-car-front-fill"></i> Urbania Hire for Corporate Events in Pune</Link></li>
                                  <li><Link to="/Tempo-Traveller-On-Rent-in-Pune"><i class="bi bi-car-front-fill"></i> Tempo Traveller On Rent in Pune</Link></li>
                                  <li><Link to="/Pune-to-Mumbai-Urbania-on-Rent"><i class="bi bi-car-front-fill"></i> Pune to Mumbai Urbania on Rent</Link></li>
                                  <li><Link to="/Pune-to-Ujjain-Urbania-On-Rent"><i class="bi bi-car-front-fill"></i> Pune to Ujjain Urbania On Rent</Link></li>
                                  <li><Link to="Innova-On-Rent-in-Pune"><i className="bi bi-car-front-fill"></i> Innova On Rent in Pune</Link></li>
                                 <li><Link to="Pune-to-Mahabaleshwar-Innova-Crysta-Cab"><i className="bi bi-car-front-fill"></i> Pune to Mahabaleshwar Innova Crysta Cab</Link></li>
                              </ul>
                                </div>
                            </div>
                        </div>
                      
                        <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget links-widget">
                                <div class="widget-title">
                                    <h3>Services</h3>
                                </div>
                                <div class="widget-content">
                                <ul className="links-list clearfix">
            <li>
                <Link to="/Pune-to-Shirdi-Tempo-Traveller-on-Rent">
                    <i class="bi bi-car-front-fill"></i> Pune to Shirdi Tempo Traveller on Rent
                </Link>
            </li>
            <li>
                <Link to="/Pune-Tempo-Traveller-Hire-for-Outstation">
                    <i class="bi bi-car-front-fill"></i> Pune Tempo Traveller Hire for Outstation
                </Link>
            </li>
            <li>
                <Link to="/Tempo-Traveller-Hire-for-Wedding-in-Pune">
                    <i class="bi bi-car-front-fill"></i> Tempo Traveller Hire for Wedding in Pune
                </Link>
            </li>
            <li>
                <Link to="/Mini-Bus-On-Rent-in-Pune">
                    <i class="bi bi-car-front-fill"></i> Mini Bus On Rent in Pune
                </Link>
            </li>
            <li>
                <Link to="/Innova-Crysta-on-Rent-in-Pune">
                    <i class="bi bi-car-front-fill"></i> Innova Crysta on Rent in Pune
                </Link>
            </li>
            <li>
                <Link to="/Pune-to-Goa-Urbania-On-Rent">
                    <i class="bi bi-car-front-fill"></i> Pune to Goa Urbania On Rent
                </Link>
            </li>
            <li><Link to="Pune-to-Shirdi-Innova-Crysta-Cab"><i className="bi bi-car-front-fill"></i> Pune to Shirdi Innova Crysta Cab</Link></li>
    <li><Link to="Innova-Crysta-Cab"><i className="bi bi-car-front-fill"></i> Innova Crysta Cab</Link></li>
    <li><Link to="Online-Cab-Booking-Pune"><i className="bi bi-car-front-fill"></i> Online Cab Booking Pune</Link></li>
    
           
                       </ul>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    {/* <div className='row '>
                    <div class="map-inner">
                <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2989.9702679499032!2d73.9347374!3d18.5276986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c174a3bebeed%3A0xd70f06012dc8b647!2sHermosa%20Casa%20By%20Sukhwani!5e1!3m2!1sen!2sin!4v1729850239456!5m2!1sen!2sin"
        width="100%"
        height="150"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      ></iframe>
    </div>
                </div>
                </div> */}
                </div>
                
            </div>
            <div class="footer-bottom text-center">
                <div class="container">
                    <div class="bottom-inner">
                        <div class=" ">
                        <div className="text-white text-center">
                          All rights reserved Shiv Travels66 2024 Developed By
                <Link to="https://dexterintelligence.com/" target="_blank"> <span className="text fw-bold colorblue">Dexter Intelligence</span> </Link> |  <Link to='https://www.urvahosting.com/' target="_blank">Hosted By <span className="text fw-bold colorblue">Urva Hosting</span></Link> 
              </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            
             {/* <!-- =============== whatsapp and phone button =============== --> */}
         <div class="phone-float">
                <a href="tel:+91 9764768866">
                <i class="bi bi-telephone-fill"></i><span>Call Us
                        <br/><small>+91 9764768866</small></span>
                </a>
            </div>

            <div class="whats-float">
                <a href="https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Force Urbania/ Tempo Traveller/ Innova Crysta/ Mini Bus With Shiv Travels Request you Please Send More Details or Call Me Thank you
 ." 
                    target="_blank">
                    <span>WhatsApp<br/><small style={{ textAlign: 'right' }}>+91 9764768866</small></span><i
                        class="bi bi-whatsapp"></i>
                </a>
              
            </div> 
        {/* ========================= */}

            
        </footer>
        </>
    )
}