import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function OnlineCabbookinginPune() {
    const [activeIndex, setActiveIndex] = useState(null); 

    const handleToggle = (index) => { 
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "I had an amazing experience using Shiv Travels66 for my online cab booking in Pune. The booking process was seamless, and the cab arrived on time. The driver was friendly and professional, making my ride comfortable. I highly recommend Shiv Travels66 for anyone looking for reliable cab services!",

    name: "Mrs.Anjali Deshmukh"
  },
  {
    quote: "Shiv Travels66 made my trip to Mumbai so easy with their online cab booking service. The cab was clean, and the driver was knowledgeable about the best routes. I appreciate their punctuality and excellent customer service. I will definitely use them again for my future travels!",

    name: "Mr. Rahul Patil"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
  };

    const faqData = [
        {
            question: "How do I book a cab online with Shiv Travels66?",
            answer: "You can book a cab by visiting our website or using our mobile app. Simply enter your pickup and drop-off locations, select your vehicle, and confirm your booking."
        },
        {
            question: "What types of vehicles are available for online cab booking?",
            answer: "We offer a variety of vehicles, including sedans, SUVs, and luxury cars. You can choose the vehicle that best suits your needs and group size."
        },
        {
            question: "Are there any hidden charges when booking a cab online?",
            answer: "No, we believe in transparent pricing. The fare displayed during the booking process includes all charges, with no hidden fees."
        },
        {
            question: "Can I modify or cancel my cab booking?",
            answer: "Yes, you can modify or cancel your booking through our website or app. Please refer to our cancellation policy for more details."
        },
        {
            question: "What payment options are available for online cab booking?",
            answer: "We accept multiple payment methods, including credit/debit cards, net banking, and cash payment after the ride."
        },
        {
            question: "Is it safe to travel with Shiv Travels66 during the pandemic?",
            answer: "Yes, we prioritize your safety. All our vehicles are regularly sanitized, and our drivers follow safety protocols to ensure a hygienic travel experience."
        },
        {
            question: "What should I do if my driver is late?",
            answer: "If your driver is late, please contact our customer support team. We will track your cab and provide you with updates."
        },
        {
            question: "Do you offer airport transfer services?",
            answer: "Yes, we provide airport transfer services from Pune to various destinations, including Mumbai Airport. You can book these services online."
        },
        {
            question: "How can I contact customer support if I have a query?",
            answer: "You can reach our customer support team through the contact number provided on our website or by using the live chat feature."
        },
        {
            question: "Are there any special offers or discounts for online cab bookings?",
            answer: "Yes, we regularly offer promotions and discounts for online bookings. Please check our website or app for the latest deals."
        }
        
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com/Online-Cab-Booking-Pune",
      "logo": "https://www.shivtravels66.com/img/Online-Cab Booking-Pune.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };


  const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];
  
  

   
  return (
    <>
       <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home| Pune to Mahabaleshwar Urbania on Rent | Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Urbania Hire for Corporate Events in Pune</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/img/Online-Cab Booking-Pune.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/Online-Cab-Booking-Pune" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content="Pune to Mahabaleshwar Urbania on Rent, Pune to Shirdi Urbania on Rent ,Urbania Hire for Outstation Pune
                ,Urbania Hire for Corporate Events in Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
     
      {/* <!-- page-title --> */}
      <section class="page-title centred car-back1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Online Cab Booking Pune</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Online-Cab Booking-Pune.jpg" alt="Online-Cab Booking-Pune" />
              </div>

              <div>
              <h1>Online Cab Booking Pune with Shiv Travels66</h1>
<p>In the bustling city of Pune, efficient transportation is crucial for both residents and visitors. At Shiv Travels66, we understand the need for reliable, convenient, and affordable travel solutions. Our online cab booking service is designed to provide you with a seamless experience, ensuring that you reach your destination comfortably and on time.</p>

<h2>Taxi Booking Pune</h2>
<p>Looking for a reliable taxi booking service in Pune? At Shiv Travels66, we offer a user-friendly platform for all your transportation needs. Our extensive fleet of vehicles ensures you have options for every occasion, whether you need a quick ride or a long-distance journey.</p>

<h2>Online Taxi Booking Pune</h2>
<p>Experience the ease of online taxi booking in Pune with our advanced booking system. Simply visit our website or use our app to book your taxi in just a few clicks. Our transparent pricing means you’ll know the fare upfront, so there are no surprises.</p>

<h2>Online Cab Service in Pune</h2>
<p>Our online cab service in Pune caters to a variety of travel needs, from airport transfers to sightseeing tours. With 24/7 availability, you can book a cab anytime, anywhere, making your travel hassle-free.</p>

<h2>Online Car Booking in Pune</h2>
<p>Online car booking in Pune has never been simpler! Choose from a wide range of vehicles, including sedans, SUVs, and luxury cars. Our booking platform is designed to provide a seamless experience, ensuring you can secure your ride quickly and conveniently.</p>

<h2>Online Cabs in Pune</h2>
<p>When searching for online cabs in Pune, look no further than Shiv Travels66. Our service is designed for comfort and reliability, with experienced drivers who know the city well. Enjoy a smooth ride to your destination.</p>

<h2>Taxi Booking in Pune</h2>
<p>At Shiv Travels66, our taxi booking in Pune is straightforward and efficient. Our intuitive platform allows you to enter your pickup and drop-off locations and select your vehicle type, all while getting a real-time fare estimate.</p>

<h2>Online Taxi Pune</h2>
<p>Searching for online taxi services in Pune? Our dedicated team is here to ensure you get to your destination safely and on time. With competitive rates and excellent customer service, we are the preferred choice for many travelers in Pune.</p>

<h2>Pune to Mumbai Cab</h2>
<p>Traveling from Pune to Mumbai? Shiv Travels66 offers a range of options for your journey, including comfortable cabs that cater to all budgets. Enjoy the scenic drive and let us handle the rest.</p>

<h2>Pune to Mumbai Airport Cab</h2>
<p>Need a Pune to Mumbai airport cab? We provide reliable airport transfer services with timely pickups and drop-offs. Our professional drivers will ensure you arrive at the airport on schedule.</p>

<h2>Pune Mumbai Cab</h2>
<p>Our Pune Mumbai cab service is designed to make your intercity travel easy and enjoyable. With a variety of vehicle options, you can choose the one that best fits your needs.</p>

<h2>Pune to Mumbai Airport Cab Fare</h2>
<p>Curious about the Pune to Mumbai airport cab fare? We offer transparent pricing with no hidden charges. Check our website for the latest fares and book your ride today!</p>

<h2>Pune Mumbai Airport Drop</h2>
<p>Ensure a hassle-free Pune Mumbai airport drop with our dedicated service. We prioritize punctuality and comfort, making your journey stress-free from start to finish.</p>

<h2>Pune to Mumbai Cheapest Cab</h2>
<p>Looking for the cheapest cab from Pune to Mumbai? At Shiv Travels66, we offer competitive rates without compromising on quality. Enjoy affordable travel options tailored to your budget.</p>

<h2>Pune to Mumbai Cab Service</h2>
<p>Our Pune to Mumbai cab service is known for its reliability and comfort. Whether you’re traveling for business or leisure, we provide a smooth ride to your destination.</p>

<h2>Best Cab Service Pune to Mumbai</h2>
<p>When it comes to finding the best cab service from Pune to Mumbai, Shiv Travels66 stands out for its reliability and customer satisfaction. Our fleet of well-maintained vehicles and experienced drivers ensures you enjoy a comfortable and hassle-free journey. With competitive pricing and excellent service, we’re your top choice for travel between these two bustling cities.</p>

<h2>Pune to Mumbai Cab Fare</h2>
<p>Worried about the Pune to Mumbai cab fare? At Shiv Travels66, we offer transparent pricing with no hidden charges. Our fare structure is designed to be competitive while providing the best service in the industry. Check our website or contact our customer service for a detailed fare breakdown tailored to your travel needs.</p>

<h2>Pune to Mumbai Cab Charges</h2>
<p>Understanding Pune to Mumbai cab charges is crucial for budget planning. Our pricing is based on factors such as distance, vehicle type, and service level. With Shiv Travels66, you can rest assured that you’ll receive fair rates without compromising on quality.</p>

<h2>One Way Cab Mumbai to Pune</h2>
<p>Need a one way cab from Mumbai to Pune? We provide flexible travel options that cater to your specific itinerary. Enjoy a direct, hassle-free ride without the need for a round trip. Our one-way cab services are ideal for travelers looking to save time and effort.</p>

<h2>Pune Mumbai Taxi Service</h2>
<p>Shiv Travels66 offers an exceptional Pune Mumbai taxi service designed for your convenience. Whether you’re traveling for business or leisure, our trained drivers ensure a smooth and enjoyable ride. Choose from a variety of vehicles to suit your preferences and group size.</p>

<h2>Taxi from Mumbai Airport to Pune</h2>
<p>Arriving at Mumbai Airport and need a taxi to Pune? We provide timely airport pickup services that ensure you reach your destination safely and comfortably. With our experienced drivers, you won’t have to worry about navigating the busy roads after a long flight.</p>

<h2>Pune to Mumbai Car</h2>
<p>Looking for a Pune to Mumbai car for your journey? Shiv Travels66 offers a range of vehicles, from economy options to luxurious rides, ensuring you find the perfect match for your travel style. Our cars are well-equipped for a comfortable ride, making your journey enjoyable.</p>

<h2>Pune Mumbai Taxi Fare</h2>
<p>Curious about the Pune Mumbai taxi fare? Our fares are structured to provide value for money, taking into account distance, vehicle type, and service quality. With Shiv Travels66, you’ll know exactly what to expect with our clear pricing policies.</p>

<h2>Pune to Mumbai International Airport Cab</h2>
<p>Traveling to Mumbai International Airport from Pune? Our cab services are tailored for seamless airport transfers. Enjoy a stress-free ride with timely pickups and drop-offs, ensuring you arrive at the airport with plenty of time to spare.</p>

<h2>Pune to Mumbai Cab Price</h2>
<p>The Pune to Mumbai cab price is designed to be affordable while maintaining high service standards. We provide various pricing options depending on your vehicle choice and travel requirements. Book your cab with Shiv Travels66 for a transparent and reliable pricing experience.</p>

<h2>Pune to Mumbai Cab Booking</h2>
<p>Ready to make your Pune to Mumbai cab booking? With Shiv Travels66, the process is quick and easy. Simply visit our website or app, enter your travel details, and choose your preferred vehicle. Our user-friendly platform ensures a smooth booking experience.</p>

<h2>Cab Booking Mumbai to Pune</h2>
<p>If you're looking for cab booking from Mumbai to Pune, look no further. Shiv Travels66 offers a seamless booking experience with flexible options to meet your travel needs. Our dedicated team is always ready to assist you with your journey, ensuring your experience is nothing short of excellent.</p>

              </div>
            

              <div>
      <h1>Online Cab Booking Pune with Shiv Travels66 Contact Number:-</h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Taxi booking Pune</p>
<p>- Online Taxi booking Pune</p>
<p>- Online cab service in Pune</p>
<p>- Online car booking in Pune</p>
<p>- Online cabs in Pune</p>
<p>- Taxi booking in Pune</p>
<p>- Online Taxi Pune</p>
<p>- Pune to Mumbai cab</p>
<p>- Pune to Mumbai airport cab</p>
<p>- Pune Mumbai cab</p>
<p>- Pune to Mumbai airport cab fare</p>
<p>- Pune Mumbai airport drop</p>
<p>- Pune to Mumbai cheapest cab</p>

          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- Pune to Mumbai cab service</p>
          <p>- Best cab service Pune to Mumbai</p>
<p>- Pune to Mumbai cab fare</p>
<p>- Pune to Mumbai cab charges</p>
<p>- One way cab Mumbai to Pune</p>
<p>- Pune Mumbai taxi service</p>
<p>- Taxi from Mumbai airport to Pune</p>
<p>- Pune to Mumbai car</p>
<p>- Pune Mumbai taxi fare</p>
<p>- Pune to Mumbai international airport cab</p>
<p>- Pune to Mumbai cab price</p>
<p>- Pune to Mumbai cab booking</p>
<p>- Cab booking Mumbai to Pune</p>
          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h1>Why Choose Us for Online Cab Booking in Pune with Shiv Travels66?</h1>
<ol>
    <li>
        <strong>User-Friendly Booking Platform:</strong> Our online cab booking system is designed for ease and convenience. You can book your ride in just a few clicks, ensuring a hassle-free experience every time.
    </li>
    <li>
        <strong>Wide Range of Vehicle Options:</strong> At Shiv Travels66, we offer a diverse fleet of vehicles to cater to your specific needs, whether you need a compact car for solo travel or a spacious vehicle for group outings.
    </li>
    <li>
        <strong>Transparent Pricing:</strong> We believe in transparency when it comes to pricing. Our fare structure is clear and straightforward, with no hidden fees, allowing you to budget your travel effectively.
    </li>
    <li>
        <strong>24/7 Customer Support:</strong> Our dedicated customer support team is available around the clock to assist you with any queries or concerns. Whether you need help with booking or have a question during your journey, we’re just a call away.
    </li>
    <li>
        <strong>Experienced Drivers:</strong> All our drivers are professionally trained and experienced, ensuring your safety and comfort on the road. They are knowledgeable about the best routes and traffic conditions, providing you with a smooth ride.
    </li>
    <li>
        <strong>Timely Services:</strong> We understand the importance of punctuality. When you book with us, you can count on our drivers to arrive on time, ensuring you reach your destination as scheduled.
    </li>
    <li>
        <strong>Safety and Hygiene:</strong> Your safety is our top priority. We maintain high standards of cleanliness and ensure our vehicles are regularly sanitized, especially in the current climate, so you can travel with peace of mind.
    </li>
    <li>
        <strong>Flexible Payment Options:</strong> We offer multiple payment methods to make the transaction process easy for you. You can pay online while booking or opt for cash payment after your ride.
    </li>
    <li>
        <strong>Customer-Centric Approach:</strong> At Shiv Travels66, we prioritize our customers. We continually seek feedback to improve our services and ensure that your experience with us is exceptional.
    </li>
    <li>
        <strong>Special Offers and Discounts:</strong> We frequently provide attractive deals and discounts on our cab services. When you choose Shiv Travels66, you not only get quality service but also value for your money.
    </li>
</ol>

    </div>
      
    <div className="faq-container">
    <h2> FAQs for Shiv Travels66 regarding "Online Cab Booking in Pune"?</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 regarding "Online Cab Booking in Pune":</h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
