import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";
export default function UrbaniaHireForCorporateEventInPune() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote:"We recently hired an Urbania bus from Shiv Travels66 for our corporate retreat, and the experience was fantastic! The bus was spacious and well-equipped, making our journey comfortable and enjoyable. The driver was professional and punctual, ensuring that we arrived at our destination on time. I highly recommend Shiv Travels66 for anyone looking for reliable transportation for corporate events!",
    name:"Mr. Rahul Deshmukh" 
 
  },
  {
    quote: "Shiv Travels66 provided us with an Urbania bus for our annual company conference, and I couldn't be happier with the service. The booking process was smooth, and the team was very accommodating to our schedule. The bus was clean, comfortable, and perfect for our group. It made traveling between venues hassle-free. I will definitely use Shiv Travels66 for future corporate events!",
    name: "Mrs. Priya Mehta"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            "question": "What types of corporate events do you cater to with Urbania hire?",
            "answer": "We cater to a wide range of corporate events, including conferences, meetings, team-building retreats, client entertainment, and airport transfers. Our Urbania buses are perfect for any corporate transportation needs."
        },
        {
            "question": "How many passengers can an Urbania bus accommodate?",
            "answer": "Our Urbania buses can comfortably seat up to 17 passengers, making them ideal for small to medium-sized corporate groups."
        },
        {
            "question": "Are your Urbania buses equipped with amenities?",
            "answer": "Yes, our Urbania buses come equipped with modern amenities, including air conditioning, comfortable seating, charging ports, and entertainment options, ensuring a pleasant travel experience for all passengers."
        },
        {
            "question": "Can I customize the itinerary for my Urbania hire?",
            "answer": "Absolutely! We offer flexible booking options that allow you to customize your itinerary according to your specific needs and schedule. Just let us know your requirements, and we will accommodate them."
        },
        {
            "question": "How do you ensure safety during the journey?",
            "answer": "Safety is our top priority. Our Urbania buses undergo regular maintenance and inspections, and our drivers are trained to adhere to strict safety protocols, ensuring a safe travel experience for all passengers."
        },
        {
            "question": "What is the booking process for Urbania hire?",
            "answer": "Booking an Urbania bus is simple! You can contact our customer service team via phone or email, provide your travel details, and receive a customized quote. Once confirmed, we will take care of the rest."
        },
        {
            "question": "Do you offer services for long-distance corporate events?",
            "answer": "Yes, we provide Urbania hire for both local and long-distance corporate events. Whether you're traveling within Pune or to nearby cities, we can accommodate your transportation needs."
        },
        {
            "question": "What are your payment options?",
            "answer": "We offer various payment options for your convenience, including online payments, bank transfers, and cash payments. We will provide all payment details during the booking process."
        },
        {
            "question": "Is there a cancellation policy?",
            "answer": "Yes, we have a cancellation policy in place. Please refer to our terms and conditions or contact our customer service team for more details on cancellations and any applicable fees."
        },
        {
            "question": "How can I contact Shiv Travels66 for Urbania hire?",
            "answer": "You can reach us by phone, email, or through our website contact form. Our customer service team is available to assist you with your inquiries and help you book your Urbania hire for corporate events."
        }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com/Urbania-Hire-for-Corporate-Events-in-Pune",
      "logo": "https://www.shivtravels66.com/img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  

   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];
  



   
  return (
    <>  <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/img/Urbania-Hire-for Corporate-Events in-Pune.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/Urbania-Hire-for-Corporate-Events-in-Pune" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>

     
      {/* <!-- page-title --> */}
      <section class="page-title centred background1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>hiv Travels</span>
            <h1>Urbania Hire for Corporate Events in Pune</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Urbania-Hire-for Corporate-Events in-Pune.jpg" alt="Urbania Hire for Corporate Events in Pune" />
              </div>

              <div>
              <h1>Urbania Hire for Corporate Events in Pune with Shiv Travels66</h1>
<p>When it comes to organizing corporate events in Pune, transportation plays a crucial role in ensuring a smooth and successful experience. At Shiv Travels66, we offer Urbania hire for corporate events that combines comfort, luxury, and reliability, making it the perfect choice for businesses looking to transport employees, clients, or guests.</p>

<h2>Urbania Hire for Corporate Events</h2>
<p>When planning a corporate event, the right transportation can make all the difference. Shiv Travels66 offers Urbania hire for corporate events that provides your team with a comfortable and professional travel experience. Our Urbania buses are perfect for transporting employees to conferences, meetings, and team-building events. Equipped with modern amenities and driven by experienced drivers, our service ensures punctuality and convenience, allowing your team to focus on what matters most.</p>

<h2>Urbania on Rent in Pune for Marriage Function</h2>
<p>Weddings are special occasions that require meticulous planning, and transportation is a crucial element. At Shiv Travels66, we provide Urbania on rent in Pune for marriage functions. Our spacious and luxurious Urbania buses can accommodate large groups, making it easy to transport family members and guests to and from the wedding venue. With comfortable seating and air conditioning, your guests will enjoy a pleasant journey as they celebrate your special day.</p>

<h2>Urbania on Rent in Pune for Wedding Function</h2>
<p>Make your wedding day even more memorable with our Urbania on rent in Pune for wedding functions. Our Urbania buses offer a stylish and comfortable travel option for bridal parties and guests. With ample space for luggage and elegantly designed interiors, your wedding transportation will be as beautiful as the occasion itself. Trust Shiv Travels66 to provide a seamless travel experience, allowing you to focus on creating lasting memories.</p>

<h2>Urbania on Rent in Pune for Monthly Basis</h2>
<p>For businesses or groups needing regular transportation, our Urbania on rent in Pune for monthly basis service is an ideal solution. Whether it's for daily commutes, regular events, or ongoing projects, our Urbania buses provide a reliable and comfortable travel option. With flexible rental agreements tailored to your needs, you can enjoy the convenience of having dedicated transportation at your disposal without the hassle of logistics.</p>

<h2>Force Urbania on Rent in Pimpri Chinchwad</h2>
<p>If you're located in Pimpri Chinchwad and need reliable transportation, Shiv Travels66 offers Force Urbania on rent tailored for your requirements. Whether for corporate events, family outings, or special occasions, our Force Urbania buses deliver comfort and style. Enjoy the spacious interiors, modern amenities, and the professionalism of our drivers as you travel to your destination with ease.</p>

<h2>Urbania on Rent in Kothrud Pune</h2>
<p>Look no further than Shiv Travels66! Our Urbania buses are perfect for various occasions, including corporate events, weddings, and family outings. With spacious interiors and comfortable seating, we ensure a pleasant travel experience for all passengers. Our experienced drivers prioritize safety and punctuality, making your journey worry-free. Choose Shiv Travels66 for quality Urbania rentals in Kothrud.</p>

<h2>Hire Urbania Bus on Rent with Shiv Travels</h2>
<p>When it comes to group travel, hiring an Urbania bus on rent with Shiv Travels is the best choice. Our fleet of luxury Urbania buses is designed to provide comfort and convenience for all types of trips, whether for corporate functions, social gatherings, or school outings. We offer competitive rates, flexible rental options, and a commitment to excellent service, ensuring your group travels in style and comfort. Contact us today to book your Urbania bus!</p>

<h2>Urbania on Rent in Koregaon Park Pune</h2>
<p>For those in Koregaon Park seeking a comfortable and stylish travel option, our Urbania on rent in Koregaon Park Pune is ideal. Shiv Travels66 offers a range of Urbania buses perfect for various events, from weddings to corporate functions. With spacious seating, modern amenities, and a focus on passenger comfort, our Urbania buses provide a premium travel experience. Enjoy the convenience of local transport while making lasting memories in Koregaon Park.</p>

<h2>Urbania on Rent in Pune Airport</h2>
<p>Arriving at Pune Airport and need transportation? Our Urbania on rent in Pune Airport service is designed for your convenience. Whether you are traveling with family, friends, or colleagues, our Urbania buses provide ample space for luggage and comfortable seating for all passengers. Our drivers are well-versed in the airport layout, ensuring timely pickups and drop-offs, making your travel experience seamless and stress-free.</p>

<h2>Urbania Hire in Pune Airport</h2>
<p>If you're looking for Urbania hire in Pune Airport, Shiv Travels66 has you covered! Our Urbania buses are perfect for airport transfers, providing a comfortable and stylish way to travel. Whether it’s for corporate travel, family vacations, or group tours, our dedicated team ensures that your transportation needs are met with professionalism and care. Enjoy a hassle-free experience with our reliable Urbania hire services at Pune Airport.</p>

              </div>

             

              <div>
      <h1>
Urbania Hire for Corporate Events in Pune with Shiv Travels66 Contact Number
</h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania Hire for Corporate Events in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Urbania Hire for Corporate Events</p>
          <p>- Urbania on Rent in Pune for Marriage Function</p>
          <p>- Urbania on Rent in Pune for Wedding Function</p>
          <p>- Urbania on Rent in Pune for Monthly Basis</p>
          <p>- Force Urbania on Rent in Pimpri Chinchwad</p>
         
          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- Urbania on Rent in Kothrud Pune</p>
          <p>- Hire Urbania Bus on Rent with Shiv Travels</p>
          <p>- Urbania on Rent in Koregaon Park Pune</p>
          <p>- Urbania on Rent in Pune Airport</p>
          <p>- Urbania Hire in Pune Airport</p>


        </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h2>Why Choose Us for Urbania Hire for Corporate Events in Pune with Shiv Travels66?</h2>
<p><strong>Exceptional Comfort and Space:</strong> Our Urbania buses are designed with spacious interiors that offer ample legroom and comfortable seating. Whether you're transporting a small team or a large group, everyone can relax and arrive at their destination refreshed and ready to engage.</p>
<p><strong>Professionalism You Can Trust:</strong> At Shiv Travels66, we prioritize professionalism in every aspect of our service. Our drivers are not only experienced and licensed but also trained to provide top-notch customer service. They understand the importance of punctuality and discretion, ensuring your corporate event transportation runs smoothly.</p>
<p><strong>Modern Amenities:</strong> Each Urbania bus is equipped with modern amenities, including air conditioning, charging ports, and entertainment systems. These features allow your team to stay connected, prepare for presentations, or simply enjoy a comfortable ride during the journey.</p>
<p><strong>Flexible Booking Options:</strong> We understand that corporate schedules can be dynamic. Our flexible booking options allow you to adjust your itinerary based on your specific needs. Whether you require transportation for a one-time event or ongoing services, we cater to your requirements seamlessly.</p>
<p><strong>Safety First:</strong> Safety is our top priority. Our Urbania buses undergo regular maintenance and thorough inspections to ensure they are in excellent condition. Our drivers adhere to strict safety protocols, providing you with peace of mind during your travels.</p>
<p><strong>Tailored Transportation Solutions:</strong> We offer tailored transportation solutions to meet the unique needs of your corporate events. Whether you need transportation for team-building retreats, client meetings, or corporate conferences, we work closely with you to design a custom travel plan.</p>
<p><strong>Competitive Pricing:</strong> Quality transportation doesn’t have to break the bank. We offer competitive pricing for our Urbania hire services, providing you with great value without compromising on quality or service. Our transparent pricing ensures no hidden costs, making budgeting easy.</p>
<p><strong>Dedicated Customer Support:</strong> Our customer support team is always ready to assist you. From the moment you inquire about our services to the completion of your journey, we are here to address any questions or concerns you may have. Your satisfaction is our priority.</p>
<p><strong>Positive Reputation:</strong> With numerous satisfied clients and successful corporate events under our belt, Shiv Travels66 has built a reputation for reliability and excellence. We take pride in delivering outstanding service that exceeds our clients' expectations.</p>
<p><strong>Convenient Location:</strong> Located in Pune, we are ideally positioned to serve your corporate transportation needs throughout the city and beyond. Whether you’re planning an event in the heart of Pune or a destination outside the city, we are ready to assist.</p>

    </div>
      
    <div className="faq-container">
    <h2>FAQs for Urbania Hire for Corporate Events in Pune? </h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 "Urbania Hire for Corporate Events in Pune"?</h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
