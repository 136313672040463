import { Link } from "react-router-dom";
import "./Fleet.css";
import { Helmet } from "react-helmet";

export default function Fleets(){
    const toursData = [
        {
            id: 1,
            country: "Japan",
            title: "Innova Crysta",
            price: 199,
            nights: "6 Nights / 7 Days",
            rating: 4.8,
            image: "real/gallery/shiv-travels-crysta.jpg",
            path:'https://api.whatsapp.com/send/?phone=919764768866&text= I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
        },
        {
            id: 2,
            country: "USA",
            title: "13 Seater Tempo Traveller",
            price: 250,
            nights: "4 Nights / 5 Days",
            rating: 5,
            image: "real/gallery/shivtravels-tempotravellers1.jpg",
            path:'https://api.whatsapp.com/send/?phone=919764768866&text= I want to Book 13 Seater Tempo Traveller With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
        },
        {
            id: 3,
            country: "Maldives",
            title: "13 seater urbania bus",
            price: 150,
            nights: "5 Nights / 6 Days",
            rating: 4.0,
            image: "real/urbania-shivtravels.jpg",
             path:'https://api.whatsapp.com/send/?phone=919764768866&text= I want to Book 13 seater urbania bus  With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
        },
        {
            id: 3,
            country: "Maldives",
            title: "17 Seater Tempo Traveller",
            price: 150,
            nights: "5 Nights / 6 Days",
            rating: 4.0,
            image: "real/gallery/shivtravels-tempotravellers1.jpg",
             path:'https://api.whatsapp.com/send/?phone=919764768866&text= I want to Book 17 Seater Tempo Traveller With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
        },
        {
            id: 3,
            country: "Maldives",
            title: "17 Seater Urbania Bus",
            price: 150,
            nights: "5 Nights / 6 Days",
            rating: 4.0,
            image: "real/gallery/urbania5.jpg",
             path:'https://api.whatsapp.com/send/?phone=919764768866&text= I want to Book 17 Seater Urbania Bus With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
        },



        
    ];

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "TravelAgency",
        "name": "Shiv Travels66",
        "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
        "url": "https://www.shivtravels66.com",
        "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
            "addressLocality": "Kirtane Baug Hadpasar",
            "addressRegion": "Maharashtra",
            "postalCode": "411036",
            "addressCountry": "India"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91 9764768866",
            "contactType": "Customer Service",
            "availableLanguage": "English"
        },
        "email": "booking@shivtravels66.com",
        telephone:"+91 9764768866",
        image: "https://www.shivtravels66.com/OG Image-01.jpg",
        starRating: {
          "@type": "Rating",
          bestRating: "4.8",
          ratingValue: "3",
          worstRating: "1",
        },
        "keywords": [
          "Urbania On Rent in Pune",
          "Pune to Mahabaleshwar Urbania on Rent",
          "Pune to Shirdi Urbania on Rent",
          "Urbania Hire for Outstation Pune",
          "Urbania Hire for Corporate Events in Pune"
      ]
    
    };
    
    
    return(
        <>
          <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Fleet| Urbania Hire for Corporate Events in Pune | Tempo Traveller On Rent in Pune
                | Pune to Mumbai Urbania on Rent | Pune to Ujjain Urbania On Rent</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content="  Urbania Hire for Corporate Events in Pune, Tempo Traveller On Rent in Pune
                ,Pune to Mumbai Urbania on Rent , Pune to Ujjain Urbania On Rent" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>


         {/* <!-- page-title --> */}
         <section class="page-title centred fleet-section1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Fleets</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}
            <section className="tours-section pt_100 pb_70 fleet-section">
            <div className="pattern-layer" style={{ backgroundImage: "url(assets/images/shape/shape-12.png)" }}></div>
            <div className="auto-container">
                <div className="tabs-box">
                   
                    <div className="tabs-content">
                        <div className="tab active-tab" id="tab-1">
                            <div className="row clearfix">
                                {toursData.map(tour => (
                                    <div key={tour.id} className="col-lg-3 col-md-6 col-sm-12 tours-block">
                                        <div className="tours-block-one">
                                            <div className="inner-box">
                                                <div className="image-box">
                                                    <figure className="image">
                                                        <img src={tour.image} alt={tour.title} />
                                                    </figure>
                                                    
                                                </div>
                                                <div className="lower-content text-center">
                                                  
                                                    <h4>{tour.title}</h4>
                                                    <h5>Comfortable seating available in AC and non-AC variants.</h5>
                                                    
                                                    {/* <div className="link book-link">
                                                       
                                                        <a href="https://api.whatsapp.com/send/?phone=919764768866&text= ."  target="_blank">
                                                        Book Now<i className="fas fa-long-arrow-right"></i>
                                                        </a>
                                                    </div> */}
                                                    
                                                     <div className="link book-link">
                                                       
                                                        <a href={tour.path}  target="_blank">
                                                        Book Now<i className="fas fa-long-arrow-right"></i>
                                                        </a>
                                                    </div> 


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}